import request from './../../../utils/request';
import { history } from 'umi';

export async function getControlDeviceType(): Promise<Request> {
  return request(`/kasper_api/controldevice/types`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function addControlDeviceType(params: any) {
  return request('/kasper_api/controldevice/types', {
    method: 'POST',
    data: params,
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getDeviceType() {
  return request('/kasper_api/device/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getModel() {
  return request('/kasper_api/models', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getNodeTypes() {
  return request('/kasper_api/nodes/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}


