import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getDeploymentSites,
  addNotificationEmail,
  getNotificationEmail,
  updateNotificationEmail,
  deleteNotificationEmail
} from './service';
import type { NotificationEmailData } from './data';

export interface ModelType {
  namespace: string;
  state: NotificationEmailData;
  effects: {
    createNotificationEmail: Effect;
    updateNotificationEmail: Effect;
    deleteNotificationEmail: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<NotificationEmailData>;
    clear: Reducer<NotificationEmailData>;
  };
}

const initState = {
  notificationEmailData: [],
  deploymentSiteData: []
};


const Model: ModelType = {
  namespace: 'notifyEmailCustomization',

  state: initState,

  effects: {
    *createNotificationEmail({ payload }, { call }) {
      const yieldCallResponse = yield call(addNotificationEmail, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Notification Email added successfully');
      }
    },
    *updateNotificationEmail({ payload, notification_email_id }, { call, put }) {
      const yieldCallResponse = yield call(updateNotificationEmail, payload, notification_email_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Notification Email updated successfully');
      }
    },
    *deleteNotificationEmail({ notification_email_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteNotificationEmail, notification_email_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Notification Email deleted successfully');
      }
    },
    *loadInitialData(_, { call, put }) {
      const NotificationEmailResponse = yield call(getNotificationEmail);
      initState.notificationEmailData = NotificationEmailResponse.data

      const deploymentSiteResponse = yield call(getDeploymentSites);
      initState.deploymentSiteData = deploymentSiteResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
