import type { Effect, Reducer } from 'umi';
import type { DeviceTemplateData } from './data';
import {
    getControlDeviceType,
    getNodeTypes,
    updateDeviceTemplate
} from './service';
import { message } from 'antd';

export interface ModelType {
    namespace: string;
    state: DeviceTemplateData;
    effects: {
        loadInitialData: Effect;
        updateDeviceTemplate: Effect;
    };
    reducers: {
        save: Reducer<DeviceTemplateData>;
        clear: Reducer<DeviceTemplateData>;
    };
}

const initState = {
    controlDeviceTypeData: [],
    nodeTypeData: [],
    serialStatusData: [],
    deviceTypeData: [],
    modelData: [],
    nodeActionData: [],
    outputNodeData: []
};

const Model: ModelType = {
    namespace: 'controlDeviceTypeAdvanceEdit',

    state: initState,

    effects: {
        *loadInitialData({ controlDeviceTypeId }, { call, put }) {
            const controlDeviceTypeResponse = yield call(getControlDeviceType, controlDeviceTypeId);
            initState.controlDeviceTypeData = controlDeviceTypeResponse.data;

            const nodeTypesResponse = yield call(getNodeTypes);
            initState.nodeTypeData = nodeTypesResponse.data;

            yield put({
                type: 'save',
                payload: initState,
            });
        },
        *updateDeviceTemplate({ controldeviceType_id, payload }, { call, put }) {
            const yieldCallResponse = yield call(updateDeviceTemplate, controldeviceType_id, payload);
            if (yieldCallResponse.code === '1000') {
                message.success('Control devices successfully updated');
            }
        },
    },

    reducers: {
        save(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
        clear() {
            return initState;
        },
    },
};

export default Model;
