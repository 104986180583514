import request from '../../../../utils/request';
import { history } from 'umi';

export async function getTriggerPattern(): Promise<Request> {
  return request('/kasper_api/trigger/pattern', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getControlDeviceType(): Promise<Request> {
  return request('/kasper_api/controldevice/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getDeviceType() {
  return request('/kasper_api/device/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getModel() {
  return request('/kasper_api/models', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getNodeType() {
  return request('/kasper_api/nodes/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function updateTriggerPattern(params: any, trigger_pattern_id: string) {
  return request(`/kasper_api/trigger/pattern/${trigger_pattern_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}

export async function updateNodeTypeList(data: any, trigger_pattern_id: string, node_type_list_id: string) {
  return request(`/kasper_api/trigger/pattern/nodetypelists/${trigger_pattern_id}/${node_type_list_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${history.token}` },
    data,
  });
}

export async function deleteTriggerPattern(trigger_pattern_id: string) {
  return request(`/kasper_api/trigger/pattern/${trigger_pattern_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}
