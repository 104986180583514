import request from '../../../utils/request';
import { history } from 'umi';

export async function getModule(): Promise<Request> {
  return request('/kasper_api/modules', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getModuleType(): Promise<Request> {
  return request('/kasper_api/modules/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getSubscription(): Promise<Request> {
  return request('/kasper_api/subscriptions', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getSubscriptionRole(subscription_role_id: string): Promise<Request> {
  return request(`/kasper_api/subscriptions/roles?_id=${  subscription_role_id  }`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getIdentityUsers(): Promise<Request> {
  return request('/kasper_gateway/users', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getDeploymentSite(): Promise<Request> {
  return request('/kasper_api/deploymentsites', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getUserPrivilege(tenant_id: string, user_id: string): Promise<Request> {
  return request(`/kasper_api/privileges/${tenant_id}/${user_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getTenantFromServerInstance(tenant_id: string): Promise<Request> {
  return request(`/kasper_gateway/serverinstance?tenant_id=${  tenant_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function updateUserPrivilege(params: any, tenant_id: string, user_id: string, module: string) {
  return request(`/kasper_api/privileges/${tenant_id}/${user_id}/${module}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}
