import request from '../../../../utils/request';
import { history } from 'umi';

export async function addLinkType(params: any) {
  return request('/kasper_api/link/type', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function getLinkType(): Promise<Request> {
  return request('/kasper_api/link/type', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function updateLinkType(params: any, link_type_id: string) {
  return request(`/kasper_api/link/type/${  link_type_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteLinkType(link_type_id: string) {
  return request(`/kasper_api/link/type/${  link_type_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}