import type { Effect, Reducer } from 'umi';
import {
  getNodeTypes,
  getControlDeviceType,
  getControlDeviceSerialStatus,
  updateDeviceTemplate,
  addControldeviceSerials,
  addDeviceType,
  addModel,
  deleteControlDeviceType,
  updateControlDeviceOperationSettings
} from './service';
import {
  getDeviceType,
  getModel,
  addControlDeviceType,
} from '@/pages/DeviceTemplateManager/AddDeviceTemplate/service';
import type { DeviceTemplateData } from './data';
import { message } from 'antd';

export interface ModelType {
  namespace: string;
  state: DeviceTemplateData;
  effects: {
    loadInitialData: Effect;
    createDevice: Effect;
    updateDeviceTemplate: Effect;
    createDeviceTemplate: Effect;
    deleteDeviceTemplate: Effect;
    createDeviceType: Effect;
    createModel: Effect;
    updateDeviceOperationSettings: Effect;
  };
  reducers: {
    save: Reducer<DeviceTemplateData>;
    clear: Reducer<DeviceTemplateData>
  }
}

const initState = {
  controlDeviceTypeData: [],
  nodeTypeData: [],
  serialStatusData: [],
  deviceTypeData: [],
  modelData: [],
  nodeActionData: [],
  outputNodeData: []
}

const Model: ModelType = {
  namespace: 'manageDeviceTemplate',

  state: initState,

  effects: {
    *loadInitialData(_, { call, put }) {
      const controlDeviceTypeResponse = yield call(getControlDeviceType);
      initState.controlDeviceTypeData = controlDeviceTypeResponse.data

      const nodeTypesResponse = yield call(getNodeTypes);
      initState.nodeTypeData = nodeTypesResponse.data

      const deviceSerialStatusResponse = yield call(getControlDeviceSerialStatus);
      initState.serialStatusData = deviceSerialStatusResponse.data

      const deviceTypeResponse = yield call(getDeviceType);
      initState.deviceTypeData = deviceTypeResponse.data

      const modelResponse = yield call(getModel);
      initState.modelData = modelResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
    *createDevice({ payload }, { call, put }) {
      const yieldCallResponse = yield call(addControldeviceSerials, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Control devices successfully added');
      }
    },
    *updateDeviceTemplate({ controldeviceType_id, payload }, { call, put }) {
      const yieldCallResponse = yield call(updateDeviceTemplate, controldeviceType_id, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Control devices successfully updated');
      }
    },
    *updateDeviceOperationSettings({controldeviceType_id,component_id,payload},{call,put}){

      const yieldCallResponse = yield call (updateControlDeviceOperationSettings, controldeviceType_id,component_id,payload)
      if(yieldCallResponse.code==='1000'){
        message.success('Operation setting successfully updated')
      }
    },
    *createDeviceTemplate({ payload }, { call, put }) {
      const yieldCallResponse = yield call(addControlDeviceType, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Control devices successfully cloned');
      }
    },
    *deleteDeviceTemplate({ controldeviceType_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteControlDeviceType, controldeviceType_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Control device successfully removed')
      }
    },
    *createDeviceType({ payload }, { call, put }) {
      const yieldCallResponse = yield call(addDeviceType, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Device Type successfully added');
      }
    },
    *createModel({ payload }, { call, put }) {
      const yieldCallResponse = yield call(addModel, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Device Type successfully added');
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
