// @ts-nocheck

import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import FolderOpenOutlined from '@ant-design/icons/FolderOpenOutlined';
import BulbFilled from '@ant-design/icons/BulbFilled';
import BulbOutlined from '@ant-design/icons/BulbOutlined';
import UnlockOutlined from '@ant-design/icons/UnlockOutlined';
import IssuesCloseOutlined from '@ant-design/icons/IssuesCloseOutlined';
import RollbackOutlined from '@ant-design/icons/RollbackOutlined';
import LaptopOutlined from '@ant-design/icons/LaptopOutlined'

export default {
  DashboardOutlined,
FolderOpenOutlined,
BulbFilled,
BulbOutlined,
UnlockOutlined,
IssuesCloseOutlined,
RollbackOutlined,
LaptopOutlined
}
    