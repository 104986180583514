import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addNodeType,
  getNodeType,
  updateNodeType,
  deleteNodeType
} from './service';
import type { NodeTypeData } from './data';

export interface ModelType {
  namespace: string;
  state: NodeTypeData;
  effects: {
    createNodeType: Effect;
    updateNodeType: Effect;
    deleteNodeType: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<NodeTypeData>;
    clear: Reducer<NodeTypeData>;
  };
}

const initState = {
  nodeTypeData: [],
};


const Model: ModelType = {
  namespace: 'nodeTypeManager',

  state: initState,

  effects: {
    *createNodeType({ payload }, { call }) {
      const yieldCallResponse = yield call(addNodeType, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Node Type added successfully');
      }
    },
    *updateNodeType({ payload, node_type_id }, { call, put }) {
      const yieldCallResponse = yield call(updateNodeType, payload, node_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Node Type updated successfully');
      }
    },
    *deleteNodeType({ node_type_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteNodeType, node_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Node Type deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const nodeTypeResponse = yield call(getNodeType);
      initState.nodeTypeData = nodeTypeResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
