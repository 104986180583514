// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/usr/src/app/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelModel0 from '/usr/src/app/src/pages/ApplianceManager/model.ts';
import ModelModel1 from '/usr/src/app/src/pages/DeploymentSites/ManageDeploymentSites/model.ts';
import ModelModel2 from '/usr/src/app/src/pages/DeploymentSites/ManageMaintenanceTeam/model.ts';
import ModelModel3 from '/usr/src/app/src/pages/DeploymentSites/ManageTenants/model.ts';
import ModelModel4 from '/usr/src/app/src/pages/DeploymentSites/ManageUsers/model.ts';
import ModelModel5 from '/usr/src/app/src/pages/DeviceManager/model.ts';
import ModelModel6 from '/usr/src/app/src/pages/DeviceTemplateManager/AddDeviceTemplate/model.ts';
import ModelModel7 from '/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/model.ts';
import ModelModel8 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/AddAccuracyAndSensitivity/model.ts';
import ModelModel9 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/AddNodeActionTemplate/model.ts';
import ModelModel10 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/AddTouchSensitivity/model.ts';
import ModelModel11 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/AdvanceEdit/model.ts';
import ModelModel12 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageDeviceType/model.ts';
import ModelModel13 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageModel/model.ts';
import ModelModel14 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageNodeType/model.ts';
import ModelModel15 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageTriggerPattern/model.ts';
import ModelModel16 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageTriggerType/model.ts';
import ModelModel17 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageTriggerTypeList/model.ts';
import ModelModel18 from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/TriggerPattern/model.ts';
import ModelModel19 from '/usr/src/app/src/pages/IssueManager/APIRoundTripDelay/model.ts';
import ModelModel20 from '/usr/src/app/src/pages/IssueManager/ManageIssues/model.ts';
import ModelModel21 from '/usr/src/app/src/pages/IssueManager/NotifyEmailCustomization/model.ts';
import ModelModel22 from '/usr/src/app/src/pages/IssueManager/Settings/ManageErrorCode/model.ts';
import ModelModel23 from '/usr/src/app/src/pages/IssueManager/Settings/ManageLinkType/model.ts';
import ModelModel24 from '/usr/src/app/src/pages/IssueManager/Settings/ManagePriority/model.ts';
import ModelModel25 from '/usr/src/app/src/pages/IssueManager/Settings/ManageState/model.ts';
import ModelModel26 from '/usr/src/app/src/pages/ManageRepeatType/model.ts';
import ModelModel27 from '/usr/src/app/src/pages/ModuleManager/model.ts';
import ModelModel28 from '/usr/src/app/src/pages/PermissionManager/AddSubscription/model.ts';
import ModelModel29 from '/usr/src/app/src/pages/PermissionManager/ManageSubscriptionRole/model.ts';
import ModelModel30 from '/usr/src/app/src/pages/PermissionManager/RemotePermissionUpdate/model.ts';
import ModelModel31 from '/usr/src/app/src/pages/PermissionManager/Settings/ManageModuleType/model.ts';
import ModelModel32 from '/usr/src/app/src/pages/PermissionManager/Settings/ManageRole/model.ts';
import ModelModel33 from '/usr/src/app/src/pages/PermissionManager/Settings/ModuleManager/model.ts';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'model', ...ModelModel0 });
app.model({ namespace: 'model', ...ModelModel1 });
app.model({ namespace: 'model', ...ModelModel2 });
app.model({ namespace: 'model', ...ModelModel3 });
app.model({ namespace: 'model', ...ModelModel4 });
app.model({ namespace: 'model', ...ModelModel5 });
app.model({ namespace: 'model', ...ModelModel6 });
app.model({ namespace: 'model', ...ModelModel7 });
app.model({ namespace: 'model', ...ModelModel8 });
app.model({ namespace: 'model', ...ModelModel9 });
app.model({ namespace: 'model', ...ModelModel10 });
app.model({ namespace: 'model', ...ModelModel11 });
app.model({ namespace: 'model', ...ModelModel12 });
app.model({ namespace: 'model', ...ModelModel13 });
app.model({ namespace: 'model', ...ModelModel14 });
app.model({ namespace: 'model', ...ModelModel15 });
app.model({ namespace: 'model', ...ModelModel16 });
app.model({ namespace: 'model', ...ModelModel17 });
app.model({ namespace: 'model', ...ModelModel18 });
app.model({ namespace: 'model', ...ModelModel19 });
app.model({ namespace: 'model', ...ModelModel20 });
app.model({ namespace: 'model', ...ModelModel21 });
app.model({ namespace: 'model', ...ModelModel22 });
app.model({ namespace: 'model', ...ModelModel23 });
app.model({ namespace: 'model', ...ModelModel24 });
app.model({ namespace: 'model', ...ModelModel25 });
app.model({ namespace: 'model', ...ModelModel26 });
app.model({ namespace: 'model', ...ModelModel27 });
app.model({ namespace: 'model', ...ModelModel28 });
app.model({ namespace: 'model', ...ModelModel29 });
app.model({ namespace: 'model', ...ModelModel30 });
app.model({ namespace: 'model', ...ModelModel31 });
app.model({ namespace: 'model', ...ModelModel32 });
app.model({ namespace: 'model', ...ModelModel33 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
