import request from '../../../../utils/request';
import { history } from 'umi';

export async function addDeviceType(params: any) {
  return request('/kasper_api/device/types', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function getDeviceType(): Promise<Request> {
  return request('/kasper_api/device/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function updateDeviceType(params: any, device_type_id: string) {
  return request(`/kasper_api/device/types/${  device_type_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteDeviceType(device_type_id: string) {
  return request(`/kasper_api/device/types/${  device_type_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}