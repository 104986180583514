import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getModuleType,
  getModule,
  addmodule,
  updateModule,
  deleteModule
} from './service';
import type { ModuleData } from './data';

export interface ModelType {
  namespace: string;
  state: ModuleData;
  effects: {
    createModule: Effect;
    updateModule: Effect;
    deleteModule: Effect;
    loadInitialData: Effect;
    loadModuleData: Effect;
  };
  reducers: {
    save: Reducer<ModuleData>;
    clear: Reducer<ModuleData>;
  };
}

const initState = {
  moduleTypeData: [],
  moduleData: [],
};

const Model: ModelType = {
  namespace: 'moduleNameManager',

  state: initState,

  effects: {
    *createModule({ payload }, { call }) {
      const yieldCallResponse = yield call(addmodule, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Module added successfully');
      }
    },
    *updateModule({ payload, module_id }, { call, put }) {
      const yieldCallResponse = yield call(updateModule, payload, module_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Module updated successfully');
      }
    },
    *deleteModule({ module_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteModule, module_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Module deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const moduleTypeResponse = yield call(getModuleType);
      initState.moduleTypeData = moduleTypeResponse.data
      yield put({
        type: 'save',
        payload: initState
      });
    },
    *loadModuleData({module_type_id}, { call, put }) {
      const moduleResponse = yield call(getModule, module_type_id);
      initState.moduleData = moduleResponse.data
      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
