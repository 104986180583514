import type { Effect, Reducer } from 'umi';
import {
  getDeploymentSites,
  getModule,
  getAPIRoundTripDelay
} from './service';
import type { APIRoundTripDelayData } from './data';

export interface ModelType {
  namespace: string;
  state: APIRoundTripDelayData;
  effects: {
    loadInitialData: Effect;
    loadApiRttData: Effect;
    loadModuleData: Effect;
  };
  reducers: {
    save: Reducer<APIRoundTripDelayData>;
    clear: Reducer<APIRoundTripDelayData>;
  };
}

const initState = {
  deploymentSiteData: [],
  tenantData: [],
  moduleData: [],
  apiRttData: []
};

const Model: ModelType = {
  namespace: 'apiRoundTripDelay',

  state: initState,

  effects: {
    *loadInitialData(_, { call, put }) {
      const deploymentSiteResponse = yield call(getDeploymentSites);
      initState.deploymentSiteData = deploymentSiteResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
    *loadApiRttData({ module_id }, { call, put }) {
      const apiRttResponse = yield call(getAPIRoundTripDelay, module_id);
      initState.apiRttData = apiRttResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
    *loadModuleData({ module_type_id }, { call, put }) {
      const moduleResponse = yield call(getModule, module_type_id);
      initState.moduleData = moduleResponse.data
      yield put({
        type: 'save',
        payload: initState
      });
    },

  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
