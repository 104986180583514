import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addMaintenanceTeam,
  getMaintenanceTeam,
  updateMaintenanceTeam,
  deleteMaintenanceTeam
} from './service';
import type { MaintenanceTeamData } from '../ManageMaintenanceTeam/data';

export interface ModelType {
  namespace: string;
  state: MaintenanceTeamData;
  effects: {
    createMaintenanceTeam: Effect;
    updateMaintenanceTeam: Effect;
    deleteMaintenanceTeam: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<MaintenanceTeamData>;
    clear: Reducer<MaintenanceTeamData>;
  };
}

const initState = {
  maintenanceTeamData: [],
};


const Model: ModelType = {
  namespace: 'maintenanceTeamManager',

  state: initState,

  effects: {
    *createMaintenanceTeam({ payload }, { call }) {
      const yieldCallResponse = yield call(addMaintenanceTeam, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Maintenance Team added successfully');
      }
    },
    *updateMaintenanceTeam({ payload, maintenance_team_id }, { call, put }) {
      const yieldCallResponse = yield call(updateMaintenanceTeam, payload, maintenance_team_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Maintenance Team updated successfully');
      }
    },
    *deleteMaintenanceTeam({ maintenance_team_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteMaintenanceTeam, maintenance_team_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Maintenance Team deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const maintenanceTeamResponse = yield call(getMaintenanceTeam);
      initState.maintenanceTeamData = maintenanceTeamResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
