import request from '../../../utils/request';
import { history } from 'umi';

export async function getDeploymentSites() {
    return request('/kasper_api/deploymentsites', {
        method: 'GET',
        headers: { Authorization: `Bearer ${  history.token}` },
    });
}

export async function getMaintenanceTeam() {
    return request('/kasper_api/maintenanceteam', {
        method: 'GET',
        headers: { Authorization: `Bearer ${  history.token}` },
    });
}

export async function getSubscriptions() {
    return request('/kasper_api/subscriptions', {
        method: 'GET',
        headers: { Authorization: `Bearer ${  history.token}` },
    });
}

export async function addDeploymentSite(params: any): Promise<Request> {
    return request(`/kasper_api/deploymentsites`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${  history.token}` },
        data: params,
    });
}

export async function updateDeploymentSite(params: any, deployment_site_id: string): Promise<Request> {
    return request(`/kasper_api/deploymentsites/${  deployment_site_id}`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${  history.token}` },
        data: params,
    });
}
