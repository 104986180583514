import { getDowntimeLogInRange } from '@/pages/IssueManager/ManageIssues/service';
import { message } from 'antd';
import type { Effect, Reducer } from 'umi';
import type { ManageTenantsData } from './data';
import {
  getDeploymentSite,
  getActivityLogs,
  getControlDevices,
  getSubscription,
  getLastMainboardLogs,
  putTouchSettings,
  postTouchSettings,
  getControlDeviceTypeById,
  getControlDeviceSerialById,
  getCurtainControllerOperationtime,
  postOperationTime,
  putOperationTime,
  getOperationSettings,
  putOperationSettings,
  putCurrentSensorCalibration,
  restartNodeDevice,
  getRingInfo,
  putTransferControlDevice,
  getControlDeviceSerial,
  putRingState,
  putRingTransfer,
} from './service';

export interface ModelType {
  namespace: string;
  state: ManageTenantsData;
  effects: {
    loadInitialData: Effect;
    fetchActivityLogs: Effect;
    fetchDowntimeLogs: Effect;
    fetchLastActivityLogs: Effect;
    addTouchSettings: Effect;
    updateTouchSettings: Effect;
    loadControlDeviceTypeData: Effect;
    loadControlDeviceSerialData: Effect;
    loadOperationTimeData: Effect;
    addOperationTime: Effect;
    updateOperationTime: Effect;
    loadOperationSettingsData: Effect;
    updateOperationSettings: Effect;
    updateCurrentSensorCalibration: Effect;
    updateRingState: Effect;
    initializeNodeDevice: Effect;
    loadRingData: Effect;
    transferControlDevice: Effect;
    loadAllControlDeviceSerialData: Effect;
    updateTransferRing: Effect;
  };
  reducers: {
    save: Reducer<ManageTenantsData>;
    clear: Reducer<ManageTenantsData>;
  };
}

const initState = {
  deviceList: [],
  activityLogs: [],
  deploymentSiteData: [],
  subscriptionData: [],
  lastNActivityLogs: [],
  controlDeviceTypeData: [],
  controlDeviceSerialData: [],
  operationTimeData: [],
  operationSettingsData: [],
  ringsData: [],
};

const Model: ModelType = {
  namespace: 'manageTenantsInfo',

  state: initState,

  effects: {
    *fetchActivityLogs({ tenant_id, start_time, end_time }, { call, put }) {
      const activityLogResponse = yield call(getActivityLogs, tenant_id, start_time, end_time);
      initState.activityLogs = activityLogResponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },
    *fetchDowntimeLogs({ tenant_id, start_time, end_time }, { call, put }) {
      const activityLogResponse = yield call(
        getDowntimeLogInRange,
        tenant_id,
        start_time,
        end_time,
      );
      initState.activityLogs = activityLogResponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },
    *fetchLastActivityLogs({ tenant_id, limit }, { call, put }) {
      const activityLogResponse = yield call(getLastMainboardLogs, tenant_id, limit);
      initState.lastNActivityLogs = activityLogResponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },
    *addTouchSettings({ payload }, { call }) {
      const yieldCallResponse = yield call(postTouchSettings, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Touch Settings Added');
      }
    },
    *updateTouchSettings({ device_id, payload }, { call }) {
      const yieldCallResponse = yield call(putTouchSettings, device_id, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Touch Settings Updated');
      }
    },
    *addOperationTime({ payload }, { call }) {
      const yieldCallResponse = yield call(postOperationTime, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Operation Time Added');
      }
    },
    *updateOperationTime({ device_id, payload }, { call }) {
      const yieldCallResponse = yield call(putOperationTime, device_id, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Operation Time Updated');
      }
    },
    *updateOperationSettings({ tenant_id, device_id, component_id, payload }, { call }) {
      const yieldCallResponse = yield call(
        putOperationSettings,
        tenant_id,
        device_id,
        component_id,
        payload,
      );
      if (yieldCallResponse.code === '1000') {
        message.success('Operation Settings Updated');
      }
    },

    *loadInitialData({ deployment_site_id, tenant_id }, { call, put }) {
      const tenantResponse = yield call(getDeploymentSite, deployment_site_id);
      initState.deploymentSiteData = tenantResponse.data;

      const subscriptionResponse = yield call(getSubscription);
      initState.subscriptionData = subscriptionResponse.data;

      const deviceListresponse = yield call(getControlDevices, tenant_id);
      initState.deviceList = deviceListresponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },
    *loadControlDeviceTypeData({ control_device_type_id }, { call, put }) {
      const controlDeviceTypeResponse = yield call(
        getControlDeviceTypeById,
        control_device_type_id,
      );
      initState.controlDeviceTypeData = controlDeviceTypeResponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },
    *loadControlDeviceSerialData({ control_device_id }, { call, put }) {
      const controlDeviceSerialResponse = yield call(getControlDeviceSerialById, control_device_id);
      initState.controlDeviceSerialData = controlDeviceSerialResponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },
    *loadAllControlDeviceSerialData({ control_device_type_id }, { call, put }) {
      const controlDeviceSerialResponse = yield call(
        getControlDeviceSerial,
        control_device_type_id,
      );
      initState.controlDeviceSerialData = controlDeviceSerialResponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },

    *loadOperationTimeData({ tenant_id, control_device_id }, { call, put }) {
      const operationTimeResponse = yield call(
        getCurtainControllerOperationtime,
        tenant_id,
        control_device_id,
      );
      initState.operationTimeData = operationTimeResponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },
    *loadOperationSettingsData({ tenant_id, control_device_id }, { call, put }) {
      const operationSettingsResponse = yield call(
        getOperationSettings,
        tenant_id,
        control_device_id,
      );
      initState.operationSettingsData = operationSettingsResponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },
    *updateCurrentSensorCalibration({ tenant_id, reg_id, component_id, payload }, { call }) {
      const yieldCallResponse = yield call(
        putCurrentSensorCalibration,
        tenant_id,
        reg_id,
        component_id,
        payload,
      );
      if (yieldCallResponse.code === '1000') {
        message.success('Calibration Setting Updated');
      }
    },
    *initializeNodeDevice({ tenant_id, ring, node_can_address, device_id }, { call }) {
      const yieldCallResponse = yield call(
        restartNodeDevice,
        tenant_id,
        ring,
        node_can_address,
        device_id,
      );
      if (yieldCallResponse.code === '1000') {
        message.success('Device Initialized');
      }
    },
    *loadRingData({ tenant_id }, { call, put }) {
      const ringInfoResponse = yield call(getRingInfo, tenant_id);
      initState.ringsData = ringInfoResponse.data;

      yield put({
        type: 'save',
        payload: initState,
      });
    },
    *transferControlDevice({ tenant_id, control_device_id, payload }, { call }) {
      const yieldCallResponse = yield call(
        putTransferControlDevice,
        tenant_id,
        control_device_id,
        payload,
      );
      if (yieldCallResponse.code === '1000') {
        message.success('Transfer device completed');
      }
    },
    *updateRingState({ tenant_id, ring, state }, { call }) {
      const yieldCallResponse = yield call(putRingState, tenant_id, ring, state);
      if (yieldCallResponse.code === '1000') {
        message.success('Ring state Updated');
      }
    },
    *updateTransferRing({ tenant_id, payload }, { call }) {
      const yieldCallResponse = yield call(putRingTransfer, tenant_id, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Ring Transfer Successful');
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
