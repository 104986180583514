import request from '../../../../utils/request';
import { history } from 'umi';

export async function getModuleType(): Promise<Request> {
  return request('/kasper_api/modules/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getModule(module_type_id: string) {
  return request(`/kasper_api/modules?module_type_id=${  module_type_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function addmodule(params: any) {
  return request('/kasper_api/modules', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function updateModule(params: any, module_id: string) {
  return request(`/kasper_api/modules/${  module_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteModule(module_id: string) {
  return request(`/kasper_api/modules/${  module_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

