// @ts-nocheck
import { IRoute } from '@umijs/core';
import { AnyAction } from 'redux';
import React from 'react';
import { EffectsCommandMap, SubscriptionAPI } from 'dva';
import { match } from 'react-router-dom';
import { Location, LocationState, History } from 'history';

export * from '/usr/src/app/src/pages/ApplianceManager/model';
export * from '/usr/src/app/src/pages/DeploymentSites/ManageDeploymentSites/model';
export * from '/usr/src/app/src/pages/DeploymentSites/ManageMaintenanceTeam/model';
export * from '/usr/src/app/src/pages/DeploymentSites/ManageTenants/model';
export * from '/usr/src/app/src/pages/DeploymentSites/ManageUsers/model';
export * from '/usr/src/app/src/pages/DeviceManager/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/AddDeviceTemplate/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/AddAccuracyAndSensitivity/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/AddNodeActionTemplate/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/AddTouchSensitivity/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/AdvanceEdit/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageDeviceType/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageModel/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageNodeType/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageTriggerPattern/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageTriggerType/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageTriggerTypeList/model';
export * from '/usr/src/app/src/pages/DeviceTemplateManager/Settings/TriggerPattern/model';
export * from '/usr/src/app/src/pages/IssueManager/APIRoundTripDelay/model';
export * from '/usr/src/app/src/pages/IssueManager/ManageIssues/model';
export * from '/usr/src/app/src/pages/IssueManager/NotifyEmailCustomization/model';
export * from '/usr/src/app/src/pages/IssueManager/Settings/ManageErrorCode/model';
export * from '/usr/src/app/src/pages/IssueManager/Settings/ManageLinkType/model';
export * from '/usr/src/app/src/pages/IssueManager/Settings/ManagePriority/model';
export * from '/usr/src/app/src/pages/IssueManager/Settings/ManageState/model';
export * from '/usr/src/app/src/pages/ManageRepeatType/model';
export * from '/usr/src/app/src/pages/ModuleManager/model';
export * from '/usr/src/app/src/pages/PermissionManager/AddSubscription/model';
export * from '/usr/src/app/src/pages/PermissionManager/ManageSubscriptionRole/model';
export * from '/usr/src/app/src/pages/PermissionManager/RemotePermissionUpdate/model';
export * from '/usr/src/app/src/pages/PermissionManager/Settings/ManageModuleType/model';
export * from '/usr/src/app/src/pages/PermissionManager/Settings/ManageRole/model';
export * from '/usr/src/app/src/pages/PermissionManager/Settings/ModuleManager/model';

export interface Action<T = any> {
  type: T
}

export type Reducer<S = any, A extends Action = AnyAction> = (
  state: S | undefined,
  action: A
) => S;

export type ImmerReducer<S = any, A extends Action = AnyAction> = (
  state: S,
  action: A
) => void;

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap,
) => void;

/**
 * @type P: Type of payload
 * @type C: Type of callback
 */
export type Dispatch<P = any, C = (payload: P) => void> = (action: {
  type: string;
  payload?: P;
  callback?: C;
  [key: string]: any;
}) => any;

export type Subscription = (api: SubscriptionAPI, done: Function) => void | Function;

export interface Loading {
  global: boolean;
  effects: { [key: string]: boolean | undefined };
  models: {
    [key: string]: any;
  };
}

/**
 * @type P: Params matched in dynamic routing
 */
export interface ConnectProps<
  P extends { [K in keyof P]?: string } = {},
  S = LocationState,
  T = {}
> {
  dispatch?: Dispatch;
  // https://github.com/umijs/umi/pull/2194
  match?: match<P>;
  location: Location<S> & { query: T };
  history: History;
  route: IRoute;
}

export type RequiredConnectProps<
  P extends { [K in keyof P]?: string } = {},
  S = LocationState,
  T = {}
  > = Required<ConnectProps<P, S, T>>

/**
 * @type T: React props
 * @type U: match props types
 */
export type ConnectRC<
  T = {},
  U = {},
  S = {},
  Q = {}
> = React.ForwardRefRenderFunction<any, T & RequiredConnectProps<U, S, Q>>;

