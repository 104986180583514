import type { Effect, Reducer } from 'umi';
import type {
  ControlDeviceSerial,
  Status,
  ControlDeviceType,
  DeviceType,
  ModelData
} from './data';
import {
  getControlDeviceSerial,
  getControlDeviceSerialStatus,
  getDeviceType,
  getControlDeviceType,
  getModel,
  updateControldeviceSerial
} from './service';
import { message } from 'antd';

export interface StateType {
  device_list?: ControlDeviceSerial[];
  status_list?: Status[];
  control_device_type?: ControlDeviceType[];
  device_type?: DeviceType[];
  model_data?: ModelData[];
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    fetchSerial: Effect;
    fetchStatus: Effect;
    fetchControlDeviceType: Effect;
    fetchDeviceType: Effect;
    fetchModel: Effect;
    submit: Effect;
  };
  reducers: {
    saveSerial: Reducer<StateType>;
    saveStatus: Reducer<StateType>;
    saveControlDeviceType: Reducer<StateType>;
    saveDeviceType: Reducer<StateType>;
    saveModel: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'deviceManager',

  state: {
    device_list: [],
    status_list: [],
    control_device_type: [],
    device_type: [],
    model_data: []
  },

  effects: {
    *fetchSerial(_, { call, put }) {
      const response = yield call(getControlDeviceSerial);
      yield put({
        type: 'saveSerial',
        payload: Array.isArray(response.data) ? response.data : [],
      });
    },
    *fetchStatus(_, { call, put }) {
      const response = yield call(getControlDeviceSerialStatus);
      yield put({
        type: 'saveStatus',
        payload: Array.isArray(response.data) ? response.data : [],
      });
    },
    *fetchControlDeviceType(_, { call, put }) {
      const response = yield call(getControlDeviceType);
      yield put({
        type: 'saveControlDeviceType',
        payload: Array.isArray(response.data) ? response.data : [],
      });
    },
    *fetchDeviceType(_, { call, put }) {
      const response = yield call(getDeviceType);
      yield put({
        type: 'saveDeviceType',
        payload: Array.isArray(response.data) ? response.data : [],
      });
    },
    *fetchModel(_, { call, put }) {
      const response = yield call(getModel);
      yield put({
        type: 'saveModel',
        payload: Array.isArray(response.data) ? response.data : [],
      });
    },
    *submit({ payload, controldeviceSerial_id }, { call, put }) {
      const yieldCallResponse = yield call(updateControldeviceSerial, controldeviceSerial_id, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Control device serial successfully updated');
      } else {
        message.success('Something went wrong!');
      }
    },
  },

  reducers: {
    saveSerial(state, action) {
      return {
        ...state,
        device_list: action.payload || {},
      };
    },
    saveStatus(state, action) {
      return {
        ...state,
        status_list: action.payload || {},
      };
    },
    saveControlDeviceType(state, action) {
      return {
        ...state,
        control_device_type: action.payload || {},
      };
    },
    saveDeviceType(state, action) {
      return {
        ...state,
        device_type: action.payload || {},
      };
    },
    saveModel(state, action) {
      return {
        ...state,
        model_data: action.payload || {},
      };
    },
  },
};

export default Model;
