import request from '../../../utils/request';
import { history } from 'umi';

export async function getModuleType(): Promise<Request> {
  return request('/kasper_api/modules/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getModule(): Promise<Request> {
  return request('/kasper_api/modules', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getSubscription(): Promise<Request> {
  return request('/kasper_api/subscriptions', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getSubscriptionRole(): Promise<Request> {
  return request('/kasper_api/subscriptions/roles', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function addSubscription(params: any) {
  return request('/kasper_api/subscriptions', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function updateSubscription(params: any, subscription_id: string) {
  return request(`/kasper_api/subscriptions/${  subscription_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteSubscription(subscription_id: string) {
  return request(`/kasper_api/subscriptions/${  subscription_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}