import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addRepeatType,
  getRepeatType,
  updateRepeatType,
  deleteRepeatType
} from './service';
import type { RepeatTypeData } from './data';

export interface ModelType {
  namespace: string;
  state: RepeatTypeData;
  effects: {
    createRepeatType: Effect;
    updateRepeatType: Effect;
    deleteRepeatType: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<RepeatTypeData>;
    clear: Reducer<RepeatTypeData>;
  };
}

const initState = {
  repeatTypeData: [],
};


const Model: ModelType = {
  namespace: 'repeatTypeManager',

  state: initState,

  effects: {
    *createRepeatType({ payload }, { call }) {
      const yieldCallResponse = yield call(addRepeatType, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Repeat Type added successfully');
      }
    },
    *updateRepeatType({ payload, repeat_type_id }, { call, put }) {
      const yieldCallResponse = yield call(updateRepeatType, payload, repeat_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Repeat Type updated successfully');
      }
    },
    *deleteRepeatType({ repeat_type_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteRepeatType, repeat_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Repeat Type deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const repeatTypeResponse = yield call(getRepeatType);
      initState.repeatTypeData = repeatTypeResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
