import request from '../../../../utils/request';
import { history } from 'umi';

export async function getControlDeviceType(): Promise<Request> {
  return request('/kasper_api/controldevice/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getNodeTypes() {
  return request('/kasper_api/nodes/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getModel() {
  return request('/kasper_api/models', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function updateControlDeviceType(params: any, control_device_type_id: string, component_id: number) {
  return request(`/kasper_api/controldevice/types/${control_device_type_id}/component/${component_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}
