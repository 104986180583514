import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import { addRole, getRoles, updateRole, deleteRole } from './service';
import type { RoleData } from './data';

export interface ModelType {
  namespace: string;
  state: RoleData;
  effects: {
    createRole: Effect;
    updateRole: Effect;
    deleteRole: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<RoleData>;
    clear: Reducer<RoleData>;
  };
}

const initState = {
  roleData: [],
};


const Model: ModelType = {
  namespace: 'roleManager',

  state: initState,

  effects: {
    *createRole({ payload }, { call }) {
      const yieldCallResponse = yield call(addRole, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Role added successfully');
      }
    },
    *updateRole({ payload, role_id }, { call, put }) {
      const yieldCallResponse = yield call(updateRole, payload, role_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Role updated successfully');
      }
    },
    *deleteRole({ role_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteRole, role_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Role removed successfully')
      }
    },

    *loadInitialData(_, { call, put }) {
      const roleResponse = yield call(getRoles);
      initState.roleData = roleResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
