import request from '../../../../utils/request';
import { history } from 'umi';

export async function getControlDeviceType(controlDeviceTypeId: string): Promise<Request> {
    return request(`/kasper_api/controldevice/types?_id=${controlDeviceTypeId}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function getNodeTypes() {
    return request('/kasper_api/nodes/types', {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function addControldeviceType(controldeviceType_id: string, params: any): Promise<Request> {
    return request(`/kasper_api/controldevice/types/nodeaction/${controldeviceType_id}`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${history.token}` },
        data: params,
    });
}
