import request from '../../../utils/request';
import { history } from 'umi';

export async function addMaintenanceTeam(params: any) {
  return request('/kasper_api/maintenanceteam', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function getMaintenanceTeam(): Promise<Request> {
  return request('/kasper_api/maintenanceteam', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function updateMaintenanceTeam(params: any, maintenance_team_id: string) {
  return request(`/kasper_api/maintenanceteam/${  maintenance_team_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteMaintenanceTeam(maintenance_team_id: string) {
  return request(`/kasper_api/maintenanceteam/${  maintenance_team_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}