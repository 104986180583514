import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getModule,
  getModuleType,
  getSubscription,
  getIdentityUsers,
  getDeploymentSite,
  updateUserPrivilege,
  getTenantFromServerInstance,
} from './service';
import type { SubscriptionData } from './data';

export interface ModelType {
  namespace: string;
  state: SubscriptionData;
  effects: {
    updateUserPrivilege: Effect;
    loadInitialData: Effect;
    loadTenantServerInstanceData: Effect;
  };
  reducers: {
    save: Reducer<SubscriptionData>;
    clear: Reducer<SubscriptionData>;
  };
}

const initState = {
  subscriptionData: [],
  privilegesData: [],
  moduleTypeData: [],
  moduleData: [],
  roleData: [],
  deploymentSiteData: [],
  identityUserData: [],
  serverInstanceUserData: [],
};

const Model: ModelType = {
  namespace: 'remotePermissionUpdate',

  state: initState,

  effects: {
    *updateUserPrivilege({ payload, tenant_id, user_id, module }, { call, put }) {
      const yieldCallResponse = yield call(updateUserPrivilege, payload, tenant_id, user_id, module);
      if (yieldCallResponse.code === '1000') {
        message.success('User Privilege updated successfully');
      } else {
        message.success('Something went wrong!');
      }
    },
    *loadInitialData(_, { call, put }) {
      const deploymentSiteResponse = yield call(getDeploymentSite);
      initState.deploymentSiteData = deploymentSiteResponse.data

      const subscriptionResponse = yield call(getSubscription);
      initState.subscriptionData = subscriptionResponse.data

      const userResponse = yield call(getIdentityUsers);
      initState.identityUserData = userResponse.data.Resources

      const moduleResponse = yield call(getModule);
      initState.moduleData = moduleResponse.data

      const moduleTypeResponse = yield call(getModuleType);
      initState.moduleTypeData = moduleTypeResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },

    *loadTenantServerInstanceData({ tenant_id }, { call, put }) {
      const tenantServerInstanceResponse = yield call(getTenantFromServerInstance, tenant_id);
      initState.serverInstanceUserData = tenantServerInstanceResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
