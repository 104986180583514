import request from '../../utils/request';
import { history } from 'umi';

export async function getControlDeviceType() {
  return request(`/kasper_api/controldevice/types`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getControlDeviceSerialStatus() {
  return request('/kasper_api/controldevice/serialstatus', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getControlDeviceSerial() {
  return request('/kasper_api/controldevice/serial', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getDeviceType() {
  return request('/kasper_api/device/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getModel() {
  return request('/kasper_api/models', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function updateControldeviceSerial(controldeviceType_id: string, params: any) {
  return request(`/kasper_api/controldevice/serial/${controldeviceType_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}