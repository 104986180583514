import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addPriority,
  getPriority,
  updatePriority,
  deletePriority
} from './service';
import type { PriorityData } from './data';

export interface ModelType {
  namespace: string;
  state: PriorityData;
  effects: {
    createPriority: Effect;
    updatePriority: Effect;
    deletePriority: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<PriorityData>;
    clear: Reducer<PriorityData>;
  };
}

const initState = {
  priorityData: [],
};

const Model: ModelType = {
  namespace: 'priorityManager',

  state: initState,

  effects: {
    *createPriority({ payload }, { call }) {
      const yieldCallResponse = yield call(addPriority, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Priority added successfully');
      }
    },
    *updatePriority({ payload, priority_id }, { call }) {
      const yieldCallResponse = yield call(updatePriority, payload, priority_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Priority updated successfully');
      }
    },
    *deletePriority({ priority_id }, { call }) {
      const yieldCallResponse = yield call(deletePriority, priority_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Priority deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const priorityResponse = yield call(getPriority);
      initState.priorityData = priorityResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
