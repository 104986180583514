import request from '../../../utils/request';
import { history } from 'umi';

export async function getDeploymentSites() {
  return request('/kasper_api/deploymentsites', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getTenantDeviceList(tenant_id: string) {
  return request(`/kasper_api/tenants/${  tenant_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getMaintenanceTeam() {
  return request('/kasper_api/maintenanceteam', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getPriority() {
  return request('/kasper_api/priority', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getLinkType() {
  return request('/kasper_api/link/type', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getErrorCode() {
  return request('/kasper_api/error/codes', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getState() {
  return request('/kasper_api/state', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getIssues() {
  return request('/kasper_api/device/issue', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getDowntimeLogInRange(tenant_id: string, start_time: string, end_time: string) {
  return request(
    `/kasper_api/tenants/${
     tenant_id 
    }/downtime/logs/${ 
    start_time 
    }/${ 
    end_time}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${  history.token}`,
      },
    },
  )
}

export async function getDowntimeLogsInfo(queryString: string) {
  return request(`/kasper_api/downtime/logs?${  queryString}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function addIssue(params: any) {
  return request('/kasper_api/device/issue', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function updateIssue(params: any, issue_id: string) {
  return request(`/kasper_api/device/issue/${  issue_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

