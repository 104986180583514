import request from '../../../utils/request';
import { history } from 'umi';

export async function getDeploymentSites() {
  return request('/kasper_api/deploymentsites', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function addNotificationEmail(payload: any) {
  return request('/kasper_api/notification/emails', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: payload,
  });
}

export async function getNotificationEmail(): Promise<Request> {
  return request('/kasper_api/notification/emails', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function updateNotificationEmail(payload: any, notification_email_id: string) {
  return request(`/kasper_api/notification/emails/${ notification_email_id }`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: payload,
  });
}

export async function deleteNotificationEmail(notification_email_id: string) {
  return request(`/kasper_api/notification/emails/${ notification_email_id }`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}
