import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addTriggerTypeList,
  getTriggerType,
  getTriggerPattern,
  getTriggerTypeList,
  updateTriggerTypeList,
  deleteTriggerTypeList
} from './service';
import type { TriggerTypeListData } from './data';

export interface ModelType {
  namespace: string;
  state: TriggerTypeListData;
  effects: {
    createTriggerTypeList: Effect;
    updateTriggerTypeList: Effect;
    deleteTriggerTypeList: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<TriggerTypeListData>;
    clear: Reducer<TriggerTypeListData>;
  };
}

const initState = {
  triggerTypeData: [],
  triggerPatternData: [],
  triggerTypeListData: [],
};

const Model: ModelType = {
  namespace: 'triggerTypeListManager',

  state: initState,

  effects: {
    *createTriggerTypeList({ payload }, { call }) {
      const yieldCallResponse = yield call(addTriggerTypeList, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Trigger type list added successfully');
      }
    },
    *updateTriggerTypeList({ payload, trigger_type_list_id }, { call, put }) {
      const yieldCallResponse = yield call(updateTriggerTypeList, payload, trigger_type_list_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Trigger type list updated successfully');
      }
    },
    *deleteTriggerTypeList({ trigger_type_list_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteTriggerTypeList, trigger_type_list_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Trigger type list deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const triggerTypeResponse = yield call(getTriggerType);
      initState.triggerTypeData = triggerTypeResponse.data

      const triggerPatternResponse = yield call(getTriggerPattern);
      initState.triggerPatternData = triggerPatternResponse.data

      const triggerTypeListResponse = yield call(getTriggerTypeList);
      initState.triggerTypeListData = triggerTypeListResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
