import request from '../../../../utils/request';
import { history } from 'umi';

export async function addTriggerTypeList(params: any) {
  return request('/kasper_api/triggertype/list', {
    method: 'POST',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}

export async function getTriggerType(): Promise<Request> {
  return request('/kasper_api/triggertypes', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getTriggerPattern(): Promise<Request> {
  return request('/kasper_api/trigger/pattern', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getTriggerTypeList(): Promise<Request> {
  return request('/kasper_api/triggertype/lists', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function updateTriggerTypeList(params: any, trigger_type_list_id: string) {
  return request(`/kasper_api/triggertype/list/${trigger_type_list_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}

export async function deleteTriggerTypeList(trigger_type_list_id: string) {
  return request(`/kasper_api/triggertype/list/${trigger_type_list_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}
