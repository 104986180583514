import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addTriggerType,
  getTriggerType,
  updateTriggerType,
  deleteTriggerType
} from './service';
import type { TriggerTypeData } from './data';

export interface TriggerTypeType {
  namespace: string;
  state: TriggerTypeData;
  effects: {
    createTriggerType: Effect;
    updateTriggerType: Effect;
    deleteTriggerType: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<TriggerTypeData>;
    clear: Reducer<TriggerTypeData>;
  };
}

const initState = {
  triggerTypeData: [],
};

const TriggerType: TriggerTypeType = {
  namespace: 'triggerTypeManager',

  state: initState,

  effects: {
    *createTriggerType({ payload }, { call }) {
      const yieldCallResponse = yield call(addTriggerType, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Trigger type added successfully');
      }
    },
    *updateTriggerType({ payload, trigger_type_id }, { call, put }) {
      const yieldCallResponse = yield call(updateTriggerType, payload, trigger_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Trigger type updated successfully');
      }
    },
    *deleteTriggerType({ trigger_type_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteTriggerType, trigger_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Trigger type deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const triggerTypeResponse = yield call(getTriggerType);
      initState.triggerTypeData = triggerTypeResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default TriggerType;
