import type { Effect, Reducer } from 'umi';
import type { DeviceTemplateData } from './data';
import { addControldeviceType, getControlDeviceType, getNodeTypes } from './service';
import { message } from 'antd';

export interface ModelType {
    namespace: string;
    state: DeviceTemplateData;
    effects: {
        loadInitialData: Effect;
        createNodeActions: Effect;
    };
    reducers: {
        save: Reducer<DeviceTemplateData>;
        clear: Reducer<DeviceTemplateData>;
    };
}

const initState = {
    controlDeviceTypeData: [],
    nodeTypeData: [],
};

const Model: ModelType = {
    namespace: 'nodeActionTemplate',

    state: initState,

    effects: {
        *loadInitialData({ controlDeviceTypeId }, { call, put }) {
            const controlDeviceTypeResponse = yield call(getControlDeviceType, controlDeviceTypeId);
            initState.controlDeviceTypeData = controlDeviceTypeResponse.data;

            const nodeTypesResponse = yield call(getNodeTypes);
            initState.nodeTypeData = nodeTypesResponse.data;

            yield put({
                type: 'save',
                payload: initState,
            });
        },
        *createNodeActions({ payload, controldeviceType_id }, { call, put }) {
            const yeildCallResponse = yield call(addControldeviceType, controldeviceType_id, payload);
            if (yeildCallResponse.code === '1000') {
                message.success('Node Action Saved');
            }
        },
    },

    reducers: {
        save(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
        clear() {
            return initState;
        },
    },
};

export default Model;
