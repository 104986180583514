import { DefaultFooter } from '@ant-design/pro-layout';

export default () => {

  return (
    <DefaultFooter
      copyright={`2020-2022 KASPER`}
      links={[
        {
          key: 'Zhark-Monitoring',
          title: 'Zhark-Monitoring',
          href: 'http://www.kasperworld.com',
          blankTarget: true,
        },
      ]}
    />
  );
};
