import request from '../../utils/request'
import {history } from 'umi'

export async function getApplianceModels(){
    return request(`/kasper_api/appliances/models`,{
        method:'GET',
        headers: { Authorization: `Bearer ${history?.token}` },
    })
}

export async function postApplianceModel(data: any) {
    return request(`/kasper_api/appliances/models`,{
        method:'POST',
        headers:{ Authorization: `Bearer ${history?.token}` },
        data
    })
}

export async function updateApplianceModel(data: any,appliance_model_id: string){
    return request(`/kasper_api/appliances/models/${appliance_model_id}`,{
        method:'PUT',
        headers:{Authorization: `Bearer ${history?.token}`},
        data
    })
}

export async function getApplianceMakes(){
    return request(`/kasper_api/appliances/makers`,{
        method:'GET',
        headers:{ Authorization: `Bearer ${history?.token}` },
    })
}

export async function postAppliacesMakes(data: any){
    return request(`/kasper_api/appliances/makers`,{
        method:'POST',
        headers:{ Authorization: `Bearer ${history?.token}` },
        data
    })
}

export async function updateApplianceMake(data: any,appliance_make_id: string){
    return request(`/kasper_api/appliances/makers/${appliance_make_id}`,{
        method:'PUT',
        headers:{Authorization: `Bearer ${history?.token}`},
        data
    })
}
