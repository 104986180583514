import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getUsers,
  getRoles,
  addUserGateway,
  getDeploymentSite,
  getSubscriptionRoles,
  getTenantFromServerInstance,
  addUserDeploymentSites,
  deleteUser,
} from './service';
import type { UserData } from './data';

export interface ModelType {
  namespace: string;
  state: UserData;
  effects: {
    loadDeplSiteData: Effect;
    loadRoleData: Effect;
    loadSubscriptionRoleData: Effect;
    loadIdentityUserData: Effect;
    loadTenantServerInstanceData: Effect;
    createUserGateway: Effect;
    createUserDeploymentSite: Effect;
    deleteUser: Effect;

  };
  reducers: {
    save: Reducer<UserData>;
    clear: Reducer<UserData>;
  };
}

const initState = {
  roleData: [],
  subscriptionRoleData: [],
  deploymentSiteData: [],
  identityUserData: [],
  serverInstanceUserData: [],
};

const Model: ModelType = {
  namespace: 'userManager',

  state: initState,

  effects: {
    *createUserGateway({ payload }, { call }) {
      const yieldCallResponse = yield call(addUserGateway, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('User added to identity server successfully');
      }
    },
    *createUserDeploymentSite({ deployment_site_id, tenant_id, payload }, { call }) {
      const yieldCallResponse = yield call(addUserDeploymentSites, deployment_site_id, tenant_id, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('User added to tenant successfully');
      }
    },
    *deleteUser({ deployment_site_id, tenant_id, user_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteUser, deployment_site_id, tenant_id, user_id);
      if (yieldCallResponse.code === '1000') {
        message.success('User deleted successfully');
      }
    },

    *loadDeplSiteData({ deployment_site_id }, { call, put }) {
      const tenantResponse = yield call(getDeploymentSite, deployment_site_id);
      initState.deploymentSiteData = tenantResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },

    *loadRoleData(_, { call, put }) {
      const roleResponse = yield call(getRoles);
      initState.roleData = roleResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },

    *loadSubscriptionRoleData({ subscription_id }, { call, put }) {
      const subscriptionRoleResponse = yield call(getSubscriptionRoles, subscription_id);
      initState.subscriptionRoleData = subscriptionRoleResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },

    *loadIdentityUserData(_, { call, put }) {
      const roleResponse = yield call(getUsers);
      initState.identityUserData = roleResponse.data.Resources

      yield put({
        type: 'save',
        payload: initState
      });
    },

    *loadTenantServerInstanceData({ tenant_id }, { call, put }) {
      const tenantServerInstanceResponse = yield call(getTenantFromServerInstance, tenant_id);
      initState.serverInstanceUserData = tenantServerInstanceResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },

  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
