import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addmoduletype,
  getModuleType,
  updateModuleType,
  deleteModuleType
} from './service';
import type { ModuleTypeData } from '../ManageModuleType/data';

export interface ModelType {
  namespace: string;
  state: ModuleTypeData;
  effects: {
    createModuleType: Effect;
    updateModuleType: Effect;
    deleteModuleType: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<ModuleTypeData>;
    clear: Reducer<ModuleTypeData>;
  };
}

const initState = {
  moduleTypeData: [],
};


const Model: ModelType = {
  namespace: 'moduleTypeManager',

  state: initState,

  effects: {
    *createModuleType({ payload }, { call }) {
      const yieldCallResponse = yield call(addmoduletype, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Module Type added successfully');
      }
    },
    *updateModuleType({ payload, module_type_id }, { call, put }) {
      const yieldCallResponse = yield call(updateModuleType, payload, module_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Module Type updated successfully');
      }
    },
    *deleteModuleType({ module_type_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteModuleType, module_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Module Type deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const moduleTypeResponse = yield call(getModuleType);
      initState.moduleTypeData = moduleTypeResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
