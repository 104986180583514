import type { Effect, Reducer } from 'umi';
import { message} from 'antd';
import type { AppliancesData } from './data';
import { getApplianceMakes, getApplianceModels,postApplianceModel ,updateApplianceModel ,postAppliacesMakes,updateApplianceMake} from './service';

export interface ModelType {
  namespace: string;
  state: AppliancesData;
  effects: {
    loadInitialData: Effect;
    createApplianceModel: Effect;
    updateApplianceModel: Effect;
    fetchApplianceMakers: Effect;
    createApplianceMaker: Effect;
    updateApplianceMaker: Effect;
  };
  reducers: {
    save: Reducer<AppliancesData>;
  };
}

const initState = {
  applianceModelsList: [],
  applianceMakersList:[]
};

const Model: ModelType = {
  namespace: 'applianceManager',
  state: initState,
  effects: {
    *loadInitialData(_,{call,put}){
       const applianceModelResponse = yield call(getApplianceModels)
       initState.applianceModelsList = applianceModelResponse.data
       yield put({
        type:'save',
        payload:initState
       })
    },
    *createApplianceModel({payload},{call,put}){
       const yieldResponse = yield call(postApplianceModel,payload)
       if(yieldResponse.code ==='1000'){
        message.success('Appliance Model created Successfully')
       }
       else{
        message.error('Something went wrong')
       }
    },
    *updateApplianceModel({payload,appliance_model_id},{call}){
      const yieldResponse = yield call(updateApplianceModel,payload,appliance_model_id)
      if(yieldResponse.code==='1000'){
        message.success('Appliance Model updated')
      }
      else{
        message.error('Something went wrong')
      }
    },
    *fetchApplianceMakers(_,{call,put}){
      const applianceMakerResponse = yield call(getApplianceMakes)
      initState.applianceMakersList = applianceMakerResponse.data
      yield put({
       type:'save',
       payload:initState
      })
   },
   *createApplianceMaker({ payload }, { call, put }) {
    const yieldResponse = yield call(postAppliacesMakes, payload);
    if (yieldResponse.code === '1000') {
      message.success('Appliance Maker created Successfully');
    } else {
      message.error('Something went wrong');
    }
  },
  *updateApplianceMaker({ payload, appliance_make_id }, { call }) {
    const yieldResponse = yield call(updateApplianceMake, payload, appliance_make_id);
    if (yieldResponse.code === '1000') {
      message.success('Appliance Make updated');
    } else {
      message.error('Something went wrong');
    }
  },
  },
  reducers:{
    save(state,{payload}){
      return{
        ...state,
        ...payload
      }
    },
  }

};

export default Model;
