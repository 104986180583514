import request from '../../../../utils/request';
import { history } from 'umi';

export async function addErrorCode(params: any) {
  return request('/kasper_api/error/codes', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function getErrorCodes(): Promise<Request> {
  return request('/kasper_api/error/codes', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function updateErrorCode(params: any, error_code_id: string) {
  return request(`/kasper_api/error/codes/${  error_code_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteErrorCode(error_code_id: string) {
  return request(`/kasper_api/error/codes/${  error_code_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}