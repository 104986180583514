import request from '../../../utils/request';
import { message } from 'antd';
import { history } from 'umi';

export async function getControlDevices(tenant_id: string) {
    return request(`/kasper_api/tenants/${tenant_id}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${history.token}`,
        },
    });
}

export async function getActivityLogs(tenant_id: string, start_time: string, end_time: string) {
    return request(
        `/kasper_api/tenants/${tenant_id
        }/mainboard/logs/${start_time
        }/${end_time}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${history.token}`,
            },
        },
    )
}

export async function pingControlDevice(tenant_id: string, _id: string) {
    const hardwareResponse = request('/kasper_api/controldevices/ping', {
        method: 'POST',
        headers: { Authorization: `Bearer ${history.token}` },
        data: {
            control_device_id: _id,
            tenant_id,
        },
    });
    hardwareResponse.then((response) => {
        if (response.code === '1000') {
            message.success('Device pinged');
        }
    })
    return hardwareResponse;
}

export async function getDeploymentSite(deployment_site_id: string): Promise<Request> {
    return request(`/kasper_api/deploymentsites?_id=${deployment_site_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function getSubscription(): Promise<Request> {
    return request('/kasper_api/subscriptions', {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function restartMainBoard(tenant_id: string) {
    const hardwareResponse = request('/kasper_api/restart/mainboard', {
        method: 'POST',
        headers: { Authorization: `Bearer ${history.token}` },
        data: {
            tenant_id,
        },
    });
    hardwareResponse.then((response) => {
        if (response.code === '1000') {
            message.success('Mainboard restarted');
        }
    })
    return hardwareResponse;
}

export async function restartBackupBoard(tenant_id: string) {
    const hardwareResponse = request('/kasper_api/restart/backupboard', {
        method: 'POST',
        headers: { Authorization: `Bearer ${history.token}` },
        data: {
            tenant_id,
        },
    });
    hardwareResponse.then((response) => {
        if (response.code === '1000') {
            message.success('Backupboard restarted');
        }
    })
    return hardwareResponse;
}

export async function restartSharedBoard(tenant_id: string) {
    const hardwareResponse = request('/kasper_api/restart/sharedboard', {
        method: 'POST',
        headers: { Authorization: `Bearer ${history.token}` },
        data: {
            tenant_id,
        },
    });
    hardwareResponse.then((response) => {
        if (response.code === '1000') {
            message.success('Sharedboard restarted');
        }
    })
    return hardwareResponse;
}

export async function restartDevices(tenant_id: string, device_id: string) {
    const hardwareResponse = request('/kasper_api/restart/devices', {
        method: 'POST',
        headers: { Authorization: `Bearer ${history.token}` },
        data: {
            tenant_id,
            device_id
        },
    });
    hardwareResponse.then((response) => {
        if (response.code === '1000') {
            message.success('Device restarted');
        }
    })
    return hardwareResponse;
}
export async function restartNodeDevice(
    tenant_id: string,
    ring: number,
    node_can_address: number,
    device_id: number
) {
    const hardwareResponse = request('/kasper_api/restart/node/devices', {
        method: 'POST',
        headers: { Authorization: `Bearer ${history.token}` },
        data: {
            tenant_id,
            ring,
            node_can_address,
            device_id
        },
    });
    hardwareResponse.then((response) => {
        if (response.code === '1000') {
            message.success('Node Device initialized');
        }
    })
    return hardwareResponse;
}

export async function changeUserState(device_id: string, data: any) {
    const hardwareResponse = request(`/kasper_api/controldevices/${device_id}`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${history.token}` },
        data: {
            tenant_id: data.tenant_id,
            user_state: data.user_state
        },
    });
    return hardwareResponse;
}

export async function getLastMainboardLogs(tenant_id: string, limit: number) {
    return request(`/kasper_api/mqtt/logs/last_record/${tenant_id}/${limit}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${history.token}`,
        },
    });
}

export async function getControlDeviceType() {
    return request('/kasper_api/controldevice/types', {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function getControlDeviceTypeById(control_device_type_id: string) {
    return request(`/kasper_api/controldevice/types?_id=${control_device_type_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function getControlDeviceSerial(control_device_type_id?: string) {
    return request(`/kasper_api/controldevice/serial?control_device_type_id=${control_device_type_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function getControlDeviceSerialById(device_id: number) {
    return request(`/kasper_api/controldevice/serial/${device_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function getTouchSensitivityByNodeRegId(tenant_id: string, node_registry_id: string) {
    return request(`/kasper_api/controldevice/touchsensitivity/${tenant_id}/${node_registry_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function postTouchSettings(data: any) {
    return request(`/kasper_api/controldevice/touchsensitivity`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${history.token}` },
        data
    });
}

export async function putTouchSettings(device_id: string, data: any) {
    return request(`/kasper_api/controldevice/serial/${device_id}`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${history.token}` },
        data
    });
}

export async function getCurtainControllerOperationtime(tenant_id: string, control_device_id: string) {
    return request(`/kasper_api/controldevices/operationtimes/${tenant_id}/${control_device_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function getOperationSettings(tenant_id: string, control_device_id: string) {
    return request(`/kasper_api/controldevices/${tenant_id}/${control_device_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${history.token}` },
    });
}

export async function getRingInfo(tenant_id: string) {
    return request(`/kasper_api/rings/info/${tenant_id}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${history.token}`,
        },
    });
}

export async function postOperationTime(data: any) {
    return request(`/kasper_api/controldevices/operationtimes`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${history.token}` },
        data
    });
}

export async function putOperationTime(device_id: string, data: any) {
    return request(`/kasper_api/controldevice/serial/${device_id}`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${history.token}` },
        data
    });
}

export async function putOperationSettings(tenant_id: string, device_id: number, component_id: number, data: any) {
    return request(`/kasper_api/controldevices/operationsettings/${tenant_id}/${device_id}/${component_id}`, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${history.token}` },
        data
    });
}

export async function putCurrentSensorCalibration(tenant_id: string,control_device_id: string,component_id: number,data: any){
    return request(`/kasper_api/controldevices/currentsensor/calibration/${tenant_id}/${control_device_id}/${component_id}`,{
        method:'PUT',
        headers:{Authorization:`Bearer ${history.token}`},
        data
    })
}

export async function putTransferControlDevice(tenant_id: string,control_device_id: number,data: any){
    return request(`/kasper_api/controldevices/serial/transferdevices/${control_device_id}/${tenant_id}`,{
        method:'PUT',
        headers:{Authorization:`Bearer ${history.token}`},
        data
    })
}
export async function putRingState(tenant_id: string, ring: number, state: boolean){
    return request(`/kasper_api/rings/${ring}/status/${state}/${tenant_id}`,{
        method:'PUT',
        headers:{Authorization:`Bearer ${history.token}`}
    })
}

export async function putRingTransfer(tenant_id: string,data: any){
    return request(`/kasper_api/controldevices/serial/transferrings/${tenant_id}`,{
        method:'PUT',
        headers:{Authorization:`Bearer ${history.token}`},
        data
    })
}