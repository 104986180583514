// @ts-nocheck

  import DashboardOutlined from '@ant-design/icons/es/icons/DashboardOutlined';
import FolderOpenOutlined from '@ant-design/icons/es/icons/FolderOpenOutlined';
import BulbFilled from '@ant-design/icons/es/icons/BulbFilled';
import BulbOutlined from '@ant-design/icons/es/icons/BulbOutlined';
import UnlockOutlined from '@ant-design/icons/es/icons/UnlockOutlined';
import IssuesCloseOutlined from '@ant-design/icons/es/icons/IssuesCloseOutlined';
import RollbackOutlined from '@ant-design/icons/es/icons/RollbackOutlined';
import LaptopOutlined from '@ant-design/icons/es/icons/LaptopOutlined'
  export default {
    DashboardOutlined,
FolderOpenOutlined,
BulbFilled,
BulbOutlined,
UnlockOutlined,
IssuesCloseOutlined,
RollbackOutlined,
LaptopOutlined
  }