import request from '../../../../utils/request';
import { history } from 'umi';

export async function addState(params: any) {
  return request('/kasper_api/state', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function getState(): Promise<Request> {
  return request('/kasper_api/state', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function updateState(params: any, state_id: string) {
  return request(`/kasper_api/state/${  state_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteState(state_id: string) {
  return request(`/kasper_api/state/${  state_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}