export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.Settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.Dashboard': 'Dashboard',
  'menu.Deployment Sites': 'Deployment Sites',
  'menu.Deployment Sites.Manage Deployment Sites': 'Manage Deployment Sites',
  'menu.Deployment Sites.Add Deployment Site': 'Add Deployment Site',
  'menu.Deployment Sites.Add Tenant': 'Add Tenant',
  'menu.Deployment Sites.Manage Maintenance Team': 'Manage Maintenance Team',
  'menu.Deployment Sites.Add Maintenance Team': 'Add Maintenance Team',
  'menu.Permission Manager.Manage Permission': 'Manage Permission',
  'menu.Permission Manager': 'Permission Manager',
  'menu.Permission Manager.Settings': 'Settings',
  'menu.Permission Manager.Settings.Manage Module Type': 'Manage Module Type',
  'menu.Permission Manager.Settings.Add Module Type': 'Add Module Type',
  'menu.Permission Manager.Settings.Manage Role': 'Manage Role',
  'menu.Permission Manager.Settings.Add Role': 'Add Role',
  'menu.Permission Manager.Settings.Module Manager': 'Module Manager',
  'menu.Permission Manager.Manage Subscription': 'Manage Subscription',
  'menu.Permission Manager.Add Subscription': 'Add Subscription',
  'menu.Permission Manager.Manage Subscriprion Role': 'Manage Subscriprion Role',
  'menu.Device Template Manager': 'Device Template Manager',
  'menu.Device Template Manager.Manage Device Template': 'Manage Device Template',
  'menu.Device Template Manager.Add Device Template.Settings': 'Add Device Template',
  'menu.Device Template Manager.Settings': 'Settings',
  'menu.Device Template Manager.Settings.Manage Node Type': 'Manage Node Type',
  'menu.Device Template Manager.Settings.Add Node Type': 'Add Node Type',
  'menu.Device Template Manager.Settings.Manage Touch Sensitivity': 'Manage Touch Sensitivity',
  'menu.Device Template Manager.Settings.Add Touch Sensitivity': 'Add Touch Sensitivity',
  'menu.Device Template Manager.Settings.Manage Accuracy and Sensitivity': 'Manage Accuracy and Sensitivity',
  'menu.Device Template Manager.Settings.Add Accuracy and Sensitivity': 'Add Accuracy and Sensitivity',
  'menu.Device Template Manager.Add Device Template': 'Add Device Template',
  'menu.Device Template Manager.Settings.Manage Model': 'Manage Model',
  'menu.Device Manager': 'Device Manager',
  'menu.Issue Manager': 'Issue Manager',
  'menu.Issue Manager.API Round Trip Delay': 'API Round Trip Delay',
  'menu.Issue Manager.Manage Issues': 'Manage Issues',
  'menu.Issue Manager.Create Issue': 'Create Issue',
  'menu.Issue Manager.Settings': 'Settings',
  'menu.Issue Manager.Settings.Manage Error Code': 'Manage Error Code',
  'menu.Issue Manager.Settings.Add Error Code': 'Add Error Code',
  'menu.Issue Manager.Settings.Manage Link Type': 'Manage Link Type',
  'menu.Issue Manager.Settings.Add Link Type': 'Add Link Type',
  'menu.Issue Manager.Settings.Manage State': 'Manage State',
  'menu.Issue Manager.Settings.Add State': 'Add State',
  'menu.Issue Manager.Settings.Manage Priority': 'Manage Priority',
  'menu.Issue Manager.Settings.Add Priority': 'Add Priority',
  'menu.Device Template Manager.Settings.Manage Device Type': 'Manage Device Type',
  'menu.Permission Manager.Remote Permission Update': 'Remote Permission Update',
  'menu.Manage Repeat Type': 'Manage Repeat Type',
  'menu.Device Template Manager.Settings.Manage Trigger Type List': 'Manage Trigger Type List',
  'menu.Device Template Manager.Settings.Trigger Pattern': 'Trigger Pattern',
  'menu.Device Template Manager.Settings.Manage Trigger Type': 'Manage Trigger Type',
  'menu.Device Template Manager.Settings.Manage Trigger Pattern': 'Manage Trigger Pattern',
  'menu.Appliances Manager': 'Appliances Manager',
  'menu.Appliances Manager.Manage Appliance Models':'Manage Appliance Models',
  'menu.Appliances Manager.Manage Appliance Make': 'Manage Appliance Make',
  'menu.Issue Manager.Notify Email Customization': 'Notify Email Customization'
};

