// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/usr/src/app/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/umi/plugin/openapi",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-openapi__openapi' */'/usr/src/app/src/.umi/plugin-openapi/openapi.tsx'), loading: LoadingComponent})
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/usr/src/app/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent})],
        "component": ((props) => dynamic({
          loader: async () => {
            const React = await import('react');
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/usr/src/app/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { usePrefersColor, context } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const { demos } = React.useContext(context);
      const [renderArgs, setRenderArgs] = React.useState([]);

      // update render args when props changed
      React.useLayoutEffect(() => {
        setRenderArgs(getDemoRenderArgs(props, demos));
      }, [props.match.params.uuid, props.location.query.wrapper, props.location.query.capture]);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          },
          loading: () => null,
        }))()
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/usr/src/app/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/usr/src/app/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1666332371000,
              "slugs": [
                {
                  "depth": 1,
                  "value": "Kasper Monitoring System",
                  "heading": "kasper-monitoring-system"
                },
                {
                  "depth": 2,
                  "value": "Prepare Environment",
                  "heading": "prepare-environment"
                },
                {
                  "depth": 2,
                  "value": "Provided Scripts",
                  "heading": "provided-scripts"
                },
                {
                  "depth": 3,
                  "value": "Start project",
                  "heading": "start-project"
                },
                {
                  "depth": 2,
                  "value": "To run project in docker local environment",
                  "heading": "to-run-project-in-docker-local-environment"
                },
                {
                  "depth": 2,
                  "value": "To run project in docker cloud environment",
                  "heading": "to-run-project-in-docker-cloud-environment"
                }
              ],
              "title": "Kasper Monitoring System"
            },
            "title": "Kasper Monitoring System"
          },
          {
            "path": "/~docs/components",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__index.md' */'/usr/src/app/src/components/index.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "filePath": "src/components/index.md",
              "updatedTime": 1666332371000,
              "title": "业务组件",
              "sidemenu": false,
              "slugs": [
                {
                  "depth": 1,
                  "value": "业务组件",
                  "heading": "业务组件"
                },
                {
                  "depth": 2,
                  "value": "Footer 页脚组件",
                  "heading": "footer-页脚组件"
                },
                {
                  "depth": 2,
                  "value": "HeaderDropdown 头部下拉列表",
                  "heading": "headerdropdown-头部下拉列表"
                },
                {
                  "depth": 2,
                  "value": "HeaderSearch 头部搜索框",
                  "heading": "headersearch-头部搜索框"
                },
                {
                  "depth": 3,
                  "value": "API",
                  "heading": "api"
                },
                {
                  "depth": 2,
                  "value": "NoticeIcon 通知工具",
                  "heading": "noticeicon-通知工具"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon API",
                  "heading": "noticeicon-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon.Tab API",
                  "heading": "noticeicontab-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIconData",
                  "heading": "noticeicondata"
                },
                {
                  "depth": 2,
                  "value": "RightContent",
                  "heading": "rightcontent"
                }
              ],
              "hasPreviewer": true,
              "group": {
                "path": "/~docs/components",
                "title": "Components"
              }
            },
            "title": "业务组件 - ant-design-pro"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/usr/src/app/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "Dashboard",
        "icon": "DashboardOutlined",
        "path": "/dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/usr/src/app/src/pages/Dashboard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Deployment Sites",
        "icon": "FolderOpenOutlined",
        "path": "/deploymentsites",
        "routes": [
          {
            "name": "Manage Deployment Sites",
            "path": "/deploymentsites/managedeploymentsites",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageDeploymentSites' */'/usr/src/app/src/pages/DeploymentSites/ManageDeploymentSites'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Manage Maintenance Team",
            "path": "/deploymentsites/managemaintenanceteam",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageMaintenanceTeam' */'/usr/src/app/src/pages/DeploymentSites/ManageMaintenanceTeam'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/tenants",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/curtaincontrollertime",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__CurtainController' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/CurtainController'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/radarsensitivitysettings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__RadarSensitivity' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/RadarSensitivity'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/sensorcalibrationsettings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__SensorCalibaration' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/SensorCalibaration'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/radarmaxdetection",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__RadarMaxRange' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/RadarMaxRange'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/radardetectmode",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__RadarDetectMode' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/RadarDetectMode'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/pirsendingtimegap",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__PIRSendingTime' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/PIRSendingTime'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/touchverification",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__TouchVerification' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/TouchVerification'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/radarsendingtime",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__RadarSendingTime' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/RadarSendingTime'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/transferdevice",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__TransferDevice' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/TransferDevice'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/users",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageUsers' */'/usr/src/app/src/pages/DeploymentSites/ManageUsers'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/mainboard/logs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__Settings__ViewAllMainBoardLogs' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/Settings/ViewAllMainBoardLogs'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/deploymentsites/downtime/logs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeploymentSites__ManageTenants__Settings__ViewAllDowntimeLogs' */'/usr/src/app/src/pages/DeploymentSites/ManageTenants/Settings/ViewAllDowntimeLogs'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "Device Template Manager",
        "icon": "BulbFilled",
        "path": "/device/template/manager",
        "routes": [
          {
            "name": "Manage Device Template",
            "path": "/device/template/manager/manage/devicetemplate",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__ManageDeviceTemplate' */'/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/template/manager/add/devicetemplate",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__AddDeviceTemplate' */'/usr/src/app/src/pages/DeviceTemplateManager/AddDeviceTemplate'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/template/manager/manage/curtaincontroller",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__ManageDeviceTemplate__CurtainController' */'/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/CurtainController'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/template/manager/manage/radarsensitivtysettings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__ManageDeviceTemplate__RadarSensitivity' */'/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/RadarSensitivity'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/template/manager/manage/sensorcalibrationsettings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__ManageDeviceTemplate__SensorCalibration' */'/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/SensorCalibration'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/template/manager/manage/radarmaxdetectionrangesetting",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__ManageDeviceTemplate__RadarMaxRange' */'/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/RadarMaxRange'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/template/manager/manage/radardetectmodesetings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__ManageDeviceTemplate__RadarDetectMode' */'/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/RadarDetectMode'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/template/manager/manage/radarsendingtimegapsettings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__ManageDeviceTemplate__RadarSendingTime' */'/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/RadarSendingTime'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/template/manager/manage/pirsendingtimegapsetings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__ManageDeviceTemplate__PIRSendingTime' */'/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/PIRSendingTime'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/template/manager/manage/touchverificationsettings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__ManageDeviceTemplate__TouchVerification' */'/usr/src/app/src/pages/DeviceTemplateManager/ManageDeviceTemplate/TouchVerification'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Settings",
            "path": "/device/template/manager/settings",
            "routes": [
              {
                "name": "Manage Node Type",
                "path": "/device/template/manager/settings/manage/nodetype",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__ManageNodeType' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageNodeType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/device/template/manager/settings/manage/touch/sensitivity",
                "exact": true
              },
              {
                "name": "Add Touch Sensitivity",
                "path": "/device/template/manager/settings/add/touch/sensitivity",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__AddTouchSensitivity' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/AddTouchSensitivity'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Add Accuracy and Sensitivity",
                "path": "/device/template/manager/settings/add/accuracy/sensitivity",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__AddAccuracyAndSensitivity' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/AddAccuracyAndSensitivity'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage Device Type",
                "path": "/device/template/manager/settings/manage/device/type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__ManageDeviceType' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageDeviceType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage Model",
                "path": "/device/template/manager/settings/manage/model",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__ManageModel' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageModel'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage Trigger Type List",
                "path": "/device/template/manager/settings/manage/triggertype/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__ManageTriggerTypeList' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageTriggerTypeList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage Trigger Pattern",
                "path": "/device/template/manager/settings/manage/trigger/pattern",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__ManageTriggerPattern' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageTriggerPattern'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage Trigger Type",
                "path": "/device/template/manager/settings/manage/trigger/type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__ManageTriggerType' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/ManageTriggerType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/device/template/manager/settings/trigger/pattern",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__TriggerPattern' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/TriggerPattern'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/device/template/manager/settings/add/nodeaction",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__AddNodeActionTemplate' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/AddNodeActionTemplate'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/device/template/manager/settings/advance/edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceTemplateManager__Settings__AdvanceEdit' */'/usr/src/app/src/pages/DeviceTemplateManager/Settings/AdvanceEdit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "Device Manager",
        "icon": "BulbOutlined",
        "path": "/device/manager",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceManager' */'/usr/src/app/src/pages/DeviceManager'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Permission Manager",
        "icon": "UnlockOutlined",
        "path": "/permission/manager",
        "routes": [
          {
            "name": "Manage Subscription",
            "path": "/permission/manager/manage/subscription",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionManager__ManageSubscription' */'/usr/src/app/src/pages/PermissionManager/ManageSubscription'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Add Subscription",
            "path": "/permission/manager/add/subscription",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionManager__AddSubscription' */'/usr/src/app/src/pages/PermissionManager/AddSubscription'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Manage Subscriprion Role",
            "path": "/permission/manager/manage/subscriptionrole",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionManager__ManageSubscriptionRole' */'/usr/src/app/src/pages/PermissionManager/ManageSubscriptionRole'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Remote Permission Update",
            "path": "/permission/manager/remote/permission/update",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionManager__RemotePermissionUpdate' */'/usr/src/app/src/pages/PermissionManager/RemotePermissionUpdate'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Settings",
            "path": "/permission/manager/settings",
            "routes": [
              {
                "name": "Module Manager",
                "path": "/permission/manager/settings/module/manager",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionManager__Settings__ModuleManager' */'/usr/src/app/src/pages/PermissionManager/Settings/ModuleManager'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/permission/manager/settings/module/manager/manage/module",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionManager__Settings__ModuleManager__ManageModule' */'/usr/src/app/src/pages/PermissionManager/Settings/ModuleManager/ManageModule'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage Module Type",
                "path": "/permission/manager/settings/manage/module/type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionManager__Settings__ManageModuleType' */'/usr/src/app/src/pages/PermissionManager/Settings/ManageModuleType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage Role",
                "path": "/permission/manager/settings/manage/role",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionManager__Settings__ManageRole' */'/usr/src/app/src/pages/PermissionManager/Settings/ManageRole'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "Issue Manager",
        "icon": "IssuesCloseOutlined",
        "path": "/issue/manager",
        "routes": [
          {
            "name": "Manage Issues",
            "path": "/issue/manager/manage/issues",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IssueManager__ManageIssues' */'/usr/src/app/src/pages/IssueManager/ManageIssues'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Create Issue",
            "path": "/issue/manager/create/issue",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IssueManager__CreateIssue' */'/usr/src/app/src/pages/IssueManager/CreateIssue'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "API Round Trip Delay",
            "path": "/issue/manager/round/trip/delay",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IssueManager__APIRoundTripDelay' */'/usr/src/app/src/pages/IssueManager/APIRoundTripDelay'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Notify Email Customization",
            "path": "/issue/manager/notify/email/customization",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IssueManager__NotifyEmailCustomization' */'/usr/src/app/src/pages/IssueManager/NotifyEmailCustomization'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Settings",
            "path": "/issue/manager/settings",
            "routes": [
              {
                "name": "Manage Error Code",
                "path": "/issue/manager/settings/manage/error/code",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IssueManager__Settings__ManageErrorCode' */'/usr/src/app/src/pages/IssueManager/Settings/ManageErrorCode'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage Link Type",
                "path": "/issue/manager/settings/manage/link/type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IssueManager__Settings__ManageLinkType' */'/usr/src/app/src/pages/IssueManager/Settings/ManageLinkType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage Priority",
                "path": "/issue/manager/settings/manage/priority",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IssueManager__Settings__ManagePriority' */'/usr/src/app/src/pages/IssueManager/Settings/ManagePriority'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "Manage State",
                "path": "/issue/manager/settings/manage/state",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IssueManager__Settings__ManageState' */'/usr/src/app/src/pages/IssueManager/Settings/ManageState'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "Manage Repeat Type",
        "path": "/manage/repeat/type",
        "icon": "RollbackOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageRepeatType' */'/usr/src/app/src/pages/ManageRepeatType'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "Appliances Manager",
        "path": "/appliances/manage",
        "icon": "LaptopOutlined",
        "routes": [
          {
            "name": "Manage Appliance Models",
            "path": "/appliances/manage/model",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ApplianceManager__ApplianceModel' */'/usr/src/app/src/pages/ApplianceManager/ApplianceModel'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Manage Appliance Make",
            "path": "/appliances/manage/make",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ApplianceManager__ApplianceMake' */'/usr/src/app/src/pages/ApplianceManager/ApplianceMake'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index.html",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/usr/src/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
