import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addLinkType,
  getLinkType,
  updateLinkType,
  deleteLinkType
} from './service';
import type { LinkTypeData } from './data';

export interface ModelType {
  namespace: string;
  state: LinkTypeData;
  effects: {
    createLinkType: Effect;
    updateLinkType: Effect;
    deleteLinkType: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<LinkTypeData>;
    clear: Reducer<LinkTypeData>;
  };
}

const initState = {
  linkTypeData: [],
};

const Model: ModelType = {
  namespace: 'linkTypeManager',

  state: initState,

  effects: {
    *createLinkType({ payload }, { call }) {
      const yieldCallResponse = yield call(addLinkType, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Link Type added successfully');
      }
    },
    *updateLinkType({ payload, link_type_id }, { call }) {
      const yieldCallResponse = yield call(updateLinkType, payload, link_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Link Type updated successfully');
      }
    },
    *deleteLinkType({ link_type_id }, { call }) {
      const yieldCallResponse = yield call(deleteLinkType, link_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Link Type deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const stateResponse = yield call(getLinkType);
      initState.linkTypeData = stateResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
