import { message } from 'antd';
import type { Effect, Reducer } from 'umi';
import type { ManageDeploymentSitesData } from './data';
import {
    getDeploymentSites,
    getMaintenanceTeam,
    addDeploymentSite,
    updateDeploymentSite,
    getSubscriptions
} from './service';

export interface ModelType {
    namespace: string;
    state: ManageDeploymentSitesData;
    effects: {
        loadInitialData: Effect;
        updateDeploymentSite: Effect;
        addDeploymentSite: Effect;
    };
    reducers: {
        save: Reducer<ManageDeploymentSitesData>;
        clear: Reducer<ManageDeploymentSitesData>;
    };
}

const initState = {
    deploymentsiteData: [],
    tenantData: [],
    maintenanceTeamData: [],
    subscriptionData: []
};

const Model: ModelType = {
    namespace: 'manageDeploymentSiteInfo',

    state: initState,

    effects: {
        *loadInitialData(_, { call, put }) {
            const deploymentsiteResponse = yield call(getDeploymentSites);
            initState.deploymentsiteData = deploymentsiteResponse.data;

            const maintenanceTeamResponse = yield call(getMaintenanceTeam);
            initState.maintenanceTeamData = maintenanceTeamResponse.data;

            const subscriptionResponse = yield call(getSubscriptions);
            initState.subscriptionData = subscriptionResponse.data;
        },
        *addDeploymentSite({ payload }, { call, put }) {
            const yieldCallResponse = yield call(addDeploymentSite, payload);
            if (yieldCallResponse.code === '1000') {
                message.success('Successfully Added');
            } else {
                message.success('Something Went Wrong!');
            }
        },
        *updateDeploymentSite({ payload, deployment_site_id }, { call, put }) {
            const yieldCallResponse = yield call(updateDeploymentSite, payload, deployment_site_id);
            if (yieldCallResponse.code === '1000') {
                message.success('Successfully Updated');
            } else {
                message.success('Something Went Wrong!');
            }
        },
    },

    reducers: {
        save(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
        clear() {
            return initState;
        },
    },
};

export default Model;
