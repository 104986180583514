import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getControlDeviceType,
  getNodeTypes,
  getModel,
  updateControlDeviceType,
} from './service';
import type { TouchSensitivityData } from './data';

export interface ModelType {
  namespace: string;
  state: TouchSensitivityData;
  effects: {
    updateControlDeviceTemplate: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<TouchSensitivityData>;
    clear: Reducer<TouchSensitivityData>;
  };
}

const initState = {
  priorityData: [],
  controlDeviceTypeData: [],
  nodeTypeData: [],
  modelData: []
};

const Model: ModelType = {
  namespace: 'touchSensitivityManager',

  state: initState,

  effects: {
    *updateControlDeviceTemplate({ payload, control_device_type_id, component_id }, { call }) {
      const yieldCallResponse = yield call(updateControlDeviceType, payload, control_device_type_id, component_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Touch sensitivity updated successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const controlDeviceTypeResponse = yield call(getControlDeviceType);
      initState.controlDeviceTypeData = controlDeviceTypeResponse.data

      const nodeTypesResponse = yield call(getNodeTypes);
      initState.nodeTypeData = nodeTypesResponse.data

      const modelResponse = yield call(getModel);
      initState.modelData = modelResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
