import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addState,
  getState,
  updateState,
  deleteState
} from './service';
import type { StateData } from '../ManageState/data';

export interface ModelType {
  namespace: string;
  state: StateData;
  effects: {
    createState: Effect;
    updateState: Effect;
    deleteState: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<StateData>;
    clear: Reducer<StateData>;
  };
}

const initState = {
  stateData: [],
};

const Model: ModelType = {
  namespace: 'stateManager',

  state: initState,

  effects: {
    *createState({ payload }, { call }) {
      const yieldCallResponse = yield call(addState, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('State added successfully');
      }
    },
    *updateState({ payload, state_id }, { call }) {
      const yieldCallResponse = yield call(updateState, payload, state_id);
      if (yieldCallResponse.code === '1000') {
        message.success('State updated successfully');
      }
    },
    *deleteState({ state_id }, { call }) {
      const yieldCallResponse = yield call(deleteState, state_id);
      if (yieldCallResponse.code === '1000') {
        message.success('State deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const stateResponse = yield call(getState);
      initState.stateData = stateResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
