import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getControlDeviceType,
  addTriggerPattern,
  getModel,
  getDeviceType,
  getNodeType
} from './service';
import type { TriggerPatternData } from './data';

export interface ModelType {
  namespace: string;
  state: TriggerPatternData;
  effects: {
    createTriggerPattern: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<TriggerPatternData>;
    clear: Reducer<TriggerPatternData>;
  };
}

const initState = {
  controlDeviceTypeData: [],
  modelData: [],
  deviceTypeData: [],
  nodeTypeData: []
};

const Model: ModelType = {
  namespace: 'triggerPattern',

  state: initState,

  effects: {
    *createTriggerPattern({ payload }, { call }) {
      const yieldCallResponse = yield call(addTriggerPattern, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Trigger pattern added successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const controlDeviceTypeResponse = yield call(getControlDeviceType);
      initState.controlDeviceTypeData = controlDeviceTypeResponse.data

      const modelResponse = yield call(getModel);
      initState.modelData = modelResponse.data

      const deviceTypeResponse = yield call(getDeviceType);
      initState.deviceTypeData = deviceTypeResponse.data

      const nodeTypeResponse = yield call(getNodeType);
      initState.nodeTypeData = nodeTypeResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
