import request from '../../../../utils/request';
import { history } from 'umi';

export async function getControlDeviceType(): Promise<Request> {
  return request('/kasper_api/controldevice/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getDeviceType() {
  return request('/kasper_api/device/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getModel() {
  return request('/kasper_api/models', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getNodeType() {
  return request('/kasper_api/nodes/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function addTriggerPattern(params: any) {
  return request('/kasper_api/trigger/pattern', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}
