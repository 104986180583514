import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addDeviceType,
  getDeviceType,
  updateDeviceType,
  deleteDeviceType
} from './service';
import type { DeviceTypeData } from './data';

export interface ModelType {
  namespace: string;
  state: DeviceTypeData;
  effects: {
    createDeviceType: Effect;
    updateDeviceType: Effect;
    deleteDeviceType: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<DeviceTypeData>;
    clear: Reducer<DeviceTypeData>;
  };
}

const initState = {
  deviceTypeData: [],
};


const Model: ModelType = {
  namespace: 'deviceTypeManager',

  state: initState,

  effects: {
    *createDeviceType({ payload }, { call }) {
      const yieldCallResponse = yield call(addDeviceType, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Device Type added successfully');
      }
    },
    *updateDeviceType({ payload, device_type_id }, { call, put }) {
      const yieldCallResponse = yield call(updateDeviceType, payload, device_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Device Type updated successfully');
      }
    },
    *deleteDeviceType({ device_type_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteDeviceType, device_type_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Device Type deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const deviceTypeResponse = yield call(getDeviceType);
      initState.deviceTypeData = deviceTypeResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
