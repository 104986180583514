import request from '../../../utils/request';
import { history } from 'umi';

export async function getModuleType() {
  return request('/kasper_api/modules/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getDeploymentSites() {
  return request('/kasper_api/deploymentsites', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getModule(module_type_id: string) {
  return request(`/kasper_api/modules?module_type_id=${module_type_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getAPIRoundTripDelay(tenant_id: string, module_id: string) {
  return request(`/kasper_api/round/trip/delay?tenant_id=${tenant_id}&module_id=${module_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function userLogin(params: any) {
  return request(`/kasper_gateway/login`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}

export async function postAPIRoundTripDelay(params: any, tenant_id: string, module_id: string) {
  return request(`/kasper_api/round/trip/delay/${tenant_id}/${module_id}`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}
