import request from '../../../utils/request';
import { history } from 'umi';

export async function getModuleType(): Promise<Request> {
  return request('/kasper_api/modules/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getModule(): Promise<Request> {
  return request('/kasper_api/modules', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getSubscription(): Promise<Request> {
  return request('/kasper_api/subscriptions', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getSubscriptionRole(package_id: string, role_id: string): Promise<Request> {
  return request(`/kasper_api/subscriptions/roles?subscription_id=${  package_id  }&role_id=${  role_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getRole(): Promise<Request> {
  return request('/kasper_api/roles', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function addSubscriptionRole(params: any) {
  return request('/kasper_api/subscriptions/roles', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function updateSubscriptionRole(params: any, subscription_role_id: string) {
  return request(`/kasper_api/subscriptions/roles/${  subscription_role_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteSubscriptionRole(subscription_role_id: string) {
  return request(`/kasper_api/subscriptions/roles/${  subscription_role_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}
