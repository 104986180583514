import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getModuleType,
  getModule,
  getSubscription,
  getSubscriptionRole,
  addSubscription,
  updateSubscription,
  deleteSubscription
} from './service';
import type { SubscriptionData } from '../ManageSubscription/data';

export interface ModelType {
  namespace: string;
  state: SubscriptionData;
  effects: {
    createSubscription: Effect;
    updateSubscription: Effect;
    deleteSubscription: Effect;
    loadInitialData: Effect;
    loadSubscriptionRoleData: Effect;
  };
  reducers: {
    save: Reducer<SubscriptionData>;
    clear: Reducer<SubscriptionData>;
  };
}

const initState = {
  subscriptionData: [],
  subscriptionRoleData: [],
  privilegesData: [],
  moduleTypeData: [],
  moduleData: []
};

const Model: ModelType = {
  namespace: 'subscriptionManager',

  state: initState,

  effects: {
    *createSubscription({ payload }, { call }) {
      const yieldCallResponse = yield call(addSubscription, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Subscription added successfully');
      } else {
        message.success('Something went wrong!');
      }
    },
    *updateSubscription({ payload, subscription_id }, { call, put }) {
      const yieldCallResponse = yield call(updateSubscription, payload, subscription_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Subscription updated successfully');
      } else {
        message.success('Something went wrong!');
      }
    },
    *deleteSubscription({ subscription_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteSubscription, subscription_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Subscription deleted successfully');
      } else {
        message.success('Something went wrong!');
      }
    },

    *loadInitialData(_, { call, put }) {
      const moduleTypeResponse = yield call(getModuleType);
      initState.moduleTypeData = moduleTypeResponse.data

      const moduleResponse = yield call(getModule);
      initState.moduleData = moduleResponse.data

      const subscriptionResponse = yield call(getSubscription);
      initState.subscriptionData = subscriptionResponse.data

      const subscriptionRoleResponse = yield call(getSubscriptionRole);
      initState.subscriptionRoleData = subscriptionRoleResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
    *loadSubscriptionRoleData(_, { call, put }) {
      const subscriptionRoleResponse = yield call(getSubscriptionRole);
      initState.subscriptionRoleData = subscriptionRoleResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    }
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
