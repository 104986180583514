import request from './../../../utils/request';
import { history } from 'umi';

export async function getControlDeviceType() {
  return request('/kasper_api/controldevice/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getControlDeviceSerialStatus() {
  return request('/kasper_api/controldevice/serialstatus', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getNodeTypes() {
  return request('/kasper_api/nodes/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function getControlDeviceSerial(controldevice_type_id: string) {
  return request(
    `/kasper_api/controldevice/serial?control_device_type_id=${controldevice_type_id}`,
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${history.token}` },
    },
  );
}

export async function addControldeviceSerials(params: any) {
  return request('/kasper_api/controldevice/serial', {
    method: 'POST',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}

export async function addDeviceType(params: any) {
  return request('/kasper_api/device/types', {
    method: 'POST',
    data: params,
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function addModel(params: any) {
  return request('/kasper_api/models', {
    method: 'POST',
    data: params,
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export async function updateDeviceTemplate(_id: string, params: any) {
  return request(`/kasper_api/controldevice/types/${_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}

export async function deleteControlDeviceType(_id: string) {
  return request(`/kasper_api/controldevice/types/${_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${history.token}` },
  });
}

export function updateControlDeviceOperationSettings(cdt_id: string, comp_id: number, params: any) {
  return request(`/kasper_api/controldevice/types/operations/settings/${cdt_id}/${comp_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${history.token}` },
    data: params,
  });
}
