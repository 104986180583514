import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import { 
  getControlDeviceType,
  getDeviceType, 
  getModel, 
  getNodeTypes, 
  addControlDeviceType
 } from './service';
import type { DeviceTemplateData } from './data';

export interface ModelType {
  namespace: string;
  state: DeviceTemplateData;
  effects: {
    addControlDeviceType: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<DeviceTemplateData>;
    clear: Reducer<DeviceTemplateData>;
  };
}

const initState = {
  deviceTypeData: [],
  modelData: [],
  controlDeviceTypeData: [],
  nodeTypeData: [],
  nodeActionData: [],
  outputNodeData: []
};


const Model: ModelType = {
  namespace: 'deviceManagerAndAddDeviceTemplate',

  state: initState,

  effects: {
    *addControlDeviceType({ payload }, { call }) {
      yield call(addControlDeviceType, payload);
      message.success('Success');
    },

    *loadInitialData(_, { call, put }) {

      const deviceTypeResponse = yield call(getDeviceType);
      initState.deviceTypeData = deviceTypeResponse.data

      const modelResponse = yield call(getModel);
      initState.modelData = modelResponse.data

      const controlDeviceTypeResponse = yield call(getControlDeviceType);
      initState.controlDeviceTypeData = controlDeviceTypeResponse.data

      const nodeTypesResponse = yield call(getNodeTypes);
      initState.nodeTypeData = nodeTypesResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
