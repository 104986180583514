import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getDeploymentSites,
  getMaintenanceTeam,
  getPriority,
  getLinkType,
  getIssues,
  getErrorCode,
  addIssue,
  updateIssue,
  getState,
} from './service';
import type { ResolutionCenterData } from './data';

export interface ModelType {
  namespace: string;
  state: ResolutionCenterData;
  effects: {
    createIssue: Effect;
    updateIssue: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<ResolutionCenterData>;
    clear: Reducer<ResolutionCenterData>;
  };
}

const initState = {
  resolutionCenterData: [],
  deploymentsiteData: [],
  tenantData: [],
  maintenanceTeamData: [],
  priorityData: [],
  linkTypeData: [],
  issueLogData: [],
  deviceIssueData: [],
  errorCodeData: [],
  stateData: []
};


const Model: ModelType = {
  namespace: 'resolutionCenter',

  state: initState,

  effects: {
    *createIssue({ payload }, { call }) {
      const yieldCallResponse = yield call(addIssue, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Issue created successfully');
      }
    },
    *updateIssue({ payload, issue_id }, { call }) {
      const yieldCallResponse = yield call(updateIssue, payload, issue_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Issue updated successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const deploymentSitesResponse = yield call(getDeploymentSites);
      initState.deploymentsiteData = deploymentSitesResponse.data

      const maintenanceTeamResponse = yield call(getMaintenanceTeam);
      initState.maintenanceTeamData = maintenanceTeamResponse.data

      const priorityResponse = yield call(getPriority);
      initState.priorityData = priorityResponse.data

      const linkTypeResponse = yield call(getLinkType);
      initState.linkTypeData = linkTypeResponse.data

      const errorCodeResponse = yield call(getErrorCode);
      initState.errorCodeData = errorCodeResponse.data

      const issueResponse = yield call(getIssues);
      initState.deviceIssueData = issueResponse.data

      const stateResponse = yield call(getState);
      initState.stateData = stateResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
