import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addModel,
  getModel,
  updateModel,
  deleteModel
} from './service';
import type { ModelData } from './data';

export interface ModelType {
  namespace: string;
  state: ModelData;
  effects: {
    createModel: Effect;
    updateModel: Effect;
    deleteModel: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<ModelData>;
    clear: Reducer<ModelData>;
  };
}

const initState = {
  modelData: [],
};


const Model: ModelType = {
  namespace: 'modelManager',

  state: initState,

  effects: {
    *createModel({ payload }, { call }) {
      const yieldCallResponse = yield call(addModel, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Model added successfully');
      }
    },
    *updateModel({ payload, model_id }, { call, put }) {
      const yieldCallResponse = yield call(updateModel, payload, model_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Model updated successfully');
      }
    },
    *deleteModel({ model_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteModel, model_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Model deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const modelResponse = yield call(getModel);
      initState.modelData = modelResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
