import request from '../../../utils/request';
import { history } from 'umi';

export async function getDeploymentSite(deployment_site_id: string): Promise<Request> {
  return request(`/kasper_api/deploymentsites?_id=${  deployment_site_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getUsers(): Promise<Request> {
  return request('/kasper_gateway/users', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getTenantFromServerInstance(tenant_id: string): Promise<Request> {
  return request(`/kasper_gateway/serverinstance?tenant_id=${  tenant_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getSubscriptionRoles(subscription_id: string): Promise<Request> {
  return request(`/kasper_api/subscriptions/roles?subscription_id=${  subscription_id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function getRoles(): Promise<Request> {
  return request('/kasper_api/roles', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function addUserGateway(params: any) {
  return request('/kasper_gateway/users', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function addUserDeploymentSites(deployment_site_id: string, tenant_id: string, params: any) {
  return request(`/kasper_api/deploymentsites/${  deployment_site_id  }/tenants/${  tenant_id  }/users`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteUser(deployment_site_id: string, tenant_id: string, user_id: string) {
  return request(`/kasper_api/deploymentsites/${  deployment_site_id  }/tenants/${  tenant_id  }/users/${  user_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}
