import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getTriggerPattern,
  getControlDeviceType,
  getDeviceType,
  getModel,
  getNodeType,
  updateTriggerPattern,
  deleteTriggerPattern,
  updateNodeTypeList
} from './service';
import type { TriggerPatternData } from './data';

export interface TriggerPatternType {
  namespace: string;
  state: TriggerPatternData;
  effects: {
    updateTriggerPattern: Effect;
    updateNodeTypeList: Effect;
    deleteTriggerPattern: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<TriggerPatternData>;
    clear: Reducer<TriggerPatternData>;
  };
}

const initState = {
  triggerPatternData: [],
  controlDeviceTypeData: [],
  modelData: [],
  deviceTypeData: [],
  nodeTypeData: []
};

const TriggerPattern: TriggerPatternType = {
  namespace: 'triggerPatternManager',

  state: initState,

  effects: {
    *updateTriggerPattern({ payload, trigger_pattern_id }, { call, put }) {
      const yieldCallResponse = yield call(updateTriggerPattern, payload, trigger_pattern_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Trigger pattern updated successfully');
      }
    },
    *updateNodeTypeList({ payload, trigger_pattern_id, node_type_list_id }, { call, put }) {
      const yieldCallResponse = yield call(updateNodeTypeList, payload, trigger_pattern_id, node_type_list_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Node type list updated successfully');
      }
    },
    *deleteTriggerPattern({ trigger_pattern_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteTriggerPattern, trigger_pattern_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Trigger pattern deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const triggerPatternResponse = yield call(getTriggerPattern);
      initState.triggerPatternData = triggerPatternResponse.data

      const controlDeviceTypeResponse = yield call(getControlDeviceType);
      initState.controlDeviceTypeData = controlDeviceTypeResponse.data

      const modelResponse = yield call(getModel);
      initState.modelData = modelResponse.data

      const deviceTypeResponse = yield call(getDeviceType);
      initState.deviceTypeData = deviceTypeResponse.data

      const nodeTypeResponse = yield call(getNodeType);
      initState.nodeTypeData = nodeTypeResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default TriggerPattern;
