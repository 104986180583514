import request from '../../utils/request';
import { history } from 'umi';

export async function addRepeatType(params: any) {
  return request('/kasper_api/repeat/types', {
    method: 'POST',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function getRepeatType(): Promise<Request> {
  return request('/kasper_api/repeat/types', {
    method: 'GET',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}

export async function updateRepeatType(params: any, repeat_type_id: string) {
  return request(`/kasper_api/repeat/types/${  repeat_type_id}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${  history.token}` },
    data: params,
  });
}

export async function deleteRepeatType(repeat_type_id: string) {
  return request(`/kasper_api/repeat/types/${  repeat_type_id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${  history.token}` },
  });
}
