import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  addErrorCode,
  getErrorCodes,
  updateErrorCode,
  deleteErrorCode
} from './service';
import type { ErrorCodeData } from './data';

export interface ModelType {
  namespace: string;
  state: ErrorCodeData;
  effects: {
    createErrorCode: Effect;
    updateErrorCode: Effect;
    deleteErrorCode: Effect;
    loadInitialData: Effect;
  };
  reducers: {
    save: Reducer<ErrorCodeData>;
    clear: Reducer<ErrorCodeData>;
  };
}

const initState = {
  errorCodeData: [],
};


const Model: ModelType = {
  namespace: 'errorCodeManager',

  state: initState,

  effects: {
    *createErrorCode({ payload }, { call }) {
      const yieldCallResponse = yield call(addErrorCode, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Error code added successfully');
      }
    },
    *updateErrorCode({ payload, error_code_id }, { call }) {
      const yieldCallResponse = yield call(updateErrorCode, payload, error_code_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Error code updated successfully');
      }
    },
    *deleteErrorCode({ error_code_id }, { call }) {
      const yieldCallResponse = yield call(deleteErrorCode, error_code_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Error code deleted successfully');
      }
    },

    *loadInitialData(_, { call, put }) {
      const errorCodeResponse = yield call(getErrorCodes);
      initState.errorCodeData = errorCodeResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
