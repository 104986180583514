import type { Effect, Reducer } from 'umi';
import { message } from 'antd';
import {
  getModuleType,
  getModule,
  getSubscription,
  getRole,
  addSubscriptionRole,
  updateSubscriptionRole,
  deleteSubscriptionRole,
} from './service';
import type { SubscriptionData } from './data';

export interface ModelType {
  namespace: string;
  state: SubscriptionData;
  effects: {
    createSubscriptionRole: Effect;
    updateSubscriptionRole: Effect;
    loadInitialData: Effect;
    deleteSubscriptionRole: Effect;
  };
  reducers: {
    save: Reducer<SubscriptionData>;
    clear: Reducer<SubscriptionData>;
  };
}

const initState = {
  subscriptionData: [],
  privilegesData: [],
  moduleTypeData: [],
  moduleData: [],
  roleData: []
};

const Model: ModelType = {
  namespace: 'subscriptionRoleManager',

  state: initState,

  effects: {
    *createSubscriptionRole({ payload }, { call }) {
      const yieldCallResponse = yield call(addSubscriptionRole, payload);
      if (yieldCallResponse.code === '1000') {
        message.success('Subscription role saved successfully');
      } else {
        message.success('Something went wrong!');
      }
    },
    *updateSubscriptionRole({ payload, subscription_role_id }, { call, put }) {
      const yieldCallResponse = yield call(updateSubscriptionRole, payload, subscription_role_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Subscription updated successfully');
      } else {
        message.success('Something went wrong!');
      }
    },
    *deleteSubscriptionRole({ subscription_role_id }, { call, put }) {
      const yieldCallResponse = yield call(deleteSubscriptionRole, subscription_role_id);
      if (yieldCallResponse.code === '1000') {
        message.success('Subscription role deleted successfully');
      } else {
        message.success('Something went wrong!');
      }
    },

    *loadInitialData(_, { call, put }) {
      const moduleTypeResponse = yield call(getModuleType);
      initState.moduleTypeData = moduleTypeResponse.data

      const moduleResponse = yield call(getModule);
      initState.moduleData = moduleResponse.data

      const subscriptionResponse = yield call(getSubscription);
      initState.subscriptionData = subscriptionResponse.data

      const roleResponse = yield call(getRole);
      initState.roleData = roleResponse.data

      yield put({
        type: 'save',
        payload: initState
      });
    }
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
